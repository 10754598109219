import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-food-provider-select',
  templateUrl: './food-provider-select.component.html',
  styleUrls: ['./food-provider-select.component.css']
})
export class FoodProviderSelectComponent implements OnInit {
  AWSCSRestauarants: any = [];
  yelpRestaurants: any = [];
  isLoading: boolean = true;
  isLoadingYelp: boolean = true;
  listLoading: boolean = false;

  constructor(private foodProviderService: FoodProviderService,
    private router: Router) {

  }

  ngOnInit(): void {
    this.foodProviderService.getRestaurants().subscribe((response) => {
      this.isLoading = false;
      this.AWSCSRestauarants = response.hits.hit;
      let newArr: any = this.AWSCSRestauarants.map((item: any) => {
        return item.fields.provider_id;
      });
      this.foodProviderService.getYelpDataFromLambda().subscribe((response) => {
        this.isLoadingYelp = false;
        this.yelpRestaurants = response.businesses.filter((yelpItem: any) => !newArr.includes(yelpItem.id));
      })
    })
  }

  onClick(retaurant: any) {
    if (Object.keys(retaurant).length > 0) {
      localStorage.setItem('flowType', 'edit');
    } else {
      localStorage.setItem('flowType', 'new');
    }
    this.foodProviderService.setCurrentRestaurant(retaurant);
    this.foodProviderService.setCurrentDish({})
    this.router.navigate(['/foodproviderinfo']);
  }

  onClickYelpRestaurant(retaurantObj: any) {
    localStorage.setItem('flowType', 'new');
    this.listLoading = true;
    this.foodProviderService.getSpecificYelpRestaurantFromLambda(retaurantObj.id).subscribe((retaurant) => {
      let formattedRestaurant: any = { fields: {} };

      if (retaurant.rating) {
        formattedRestaurant['fields']['yelp_ratings'] = retaurant['rating'].toString();
      }
      if (retaurant['hours']) {
        formattedRestaurant['fields']['open_hours'] = JSON.stringify(retaurant['hours'])
      }
      if (retaurant['photos']) {
        let media: any = [];
        retaurant['photos'].forEach((element: string) => {
          media.push({ type: 'Image', url: element, source: 'Trofimo' })
        });
        formattedRestaurant['fields']['media'] = JSON.stringify(media);
      } else {
        formattedRestaurant['fields']['media'] = '[]';
      }
      let modifiedCategories: any;
      if (retaurant['categories']) {
        modifiedCategories = retaurant['categories'].map(function (item) {
          return item['title'];
        });
      } else {
        modifiedCategories = []
      }
      formattedRestaurant['fields']['category'] = JSON.stringify(modifiedCategories);
      formattedRestaurant['fields']['name'] = retaurant['name'];
      formattedRestaurant['fields']['budget'] = retaurant['price'] ? retaurant['price'] : '';
      formattedRestaurant['fields']['phone'] = retaurant['phone'];
      formattedRestaurant['fields']['display_phone'] = retaurant['display_phone'];
      formattedRestaurant['fields']['no_of_reviews_from_yelp'] = retaurant['review_count'] ? retaurant['review_count'] : 0;
      formattedRestaurant['fields']['special_hours'] = retaurant['special_hours'] ? JSON.stringify([retaurant['special_hours']]) : '[]';
      formattedRestaurant['fields']['image_from_provider'] = retaurant['image_from_provider'] ? retaurant['image_from_provider'] : '';
      formattedRestaurant['fields']['provider_alias'] = retaurant['alias'] ? retaurant['alias'] : '';
      formattedRestaurant['fields']['provider_id'] = retaurant['id'] ? retaurant['id'] : '';
      formattedRestaurant['fields']['provider_url'] = retaurant['url'] ? retaurant['url'] : '';
      formattedRestaurant['fields']['location_address_1'] = retaurant['location'].address1 ? retaurant['location'].address1 : '';
      formattedRestaurant['fields']['location_address_2'] = retaurant['location'].address2 ? retaurant['location'].address2 : '';
      formattedRestaurant['fields']['location_address_3'] = retaurant['location'].address3 ? retaurant['location'].address3 : '';
      formattedRestaurant['fields']['location_city'] = retaurant['location']['city'] ? retaurant['location']['city'] : '';
      formattedRestaurant['fields']['location_country'] = retaurant['location']['country'] ? retaurant['location']['country'] : '';
      formattedRestaurant['fields']['location_cross_street'] = retaurant['location']['location_cross_street'] ? retaurant['location']['location_cross_street'] : ''
      formattedRestaurant['fields']['location_display_address'] = retaurant['location']['display_address'] ? retaurant['location']['display_address'].join() : '';
      formattedRestaurant['fields']['location_state'] = retaurant['location']['state'] ? retaurant['location']['state'] : '';
      formattedRestaurant['fields']['location_zip_code'] = retaurant['location']['zip_code'] ? retaurant['location']['zip_code'] : '';
      formattedRestaurant['fields']['coordinates'] = retaurant['coordinates'] ? `${retaurant['coordinates']['latitude']},${retaurant['coordinates']['longitude']}` : '';
      //console.log(formattedRestaurant)
      this.foodProviderService.setCurrentRestaurant(formattedRestaurant);
      this.foodProviderService.setCurrentDish({})
      this.listLoading = false;
      this.router.navigate(['/foodproviderinfo']);
    })


  }

}
