import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class FilterPipe implements PipeTransform {
  public transform(data: any, keys: string, searchTerm: string) {
    if (!searchTerm) return data;
    return (data || []).filter(item => keys.split(',').some(key => item.fields.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item.fields[key])));

  }
}