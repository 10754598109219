<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>
    <hr class="line" style="left: 0; right: 0">
    <form [formGroup]="foodProviderMediaForm">
        <div class="col-12 col-md-6">
            <div class="mb-3 col-6">
                <div class="col-12 col-md-12 mb-3">
                    <label for="mediatype" class="form-label">
                        Media Type
                    </label>
                    <div class="row">
                        <div *ngFor='let contentType of contentList; let i=index'>
                            <div class="form-check ">
                                <input class="form-check-input" formControlName="contentType" type="radio" [value]="contentType" [id]="'contentType'+i">
                                <span class="form-check-label">{{contentType}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <label for="media" class="form-label">
                    Media
                </label>
                <div class="mb-3">
                    <input #fileInput class="form-control" type="file" accept="image/png, image/jpeg" id="formFileMultiple" multiple (change)="selectFiles($event)">
                    <div id="column" *ngFor='let preview of previews; index as i'>
                        <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 140px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                        <img class="brightness" (click)="removeLocalImage(preview, i)" style="height: 28px;width:28px;cursor: pointer;margin-left: '9.1%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='Image'>
                        <img id="images" [src]="preview" class="preview" alt='Image'>
                    </div>
                </div>
                <button (click)="uploadImages()" *ngIf="previews.length !== 0 && !imagesUploading;" type="button" class="btn btn-info mb-3 col-7 col-md-10">
                    Upload media
                </button>
                <div *ngIf="imagesUploading; then uploadingImagesBtn"></div>
                <ng-template #uploadingImagesBtn>
                    <button class="btn btn-info mb-3 col-7 col-md-10" type="button" disabled>
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        uploading media
                    </button>
                </ng-template>
                <div class="mb-3 col-7 col-md-10">
                    <input type="text" class="form-control" id="imageUrl" formControlName="mediaInput" placeholder="Paste file URL">
                    <p [ngClass]="{'hyperlink-enabled' : mediaInput.value !== '', 'hyperlink-disabled' : mediaInput.value === ''}" class="mt-1 ml-2" (click)="addMedia()">
                        Add media
                    </p>
                </div>
                <div *ngIf="mediaInput.invalid && mediaInput.touched || mediaInput.errors" class="alert alert-danger mt-2">
                    Limit of the url exceeded.
                </div>
            </div>
            <div>
                <div *ngIf="fetchImages; then thenBlockBtn else elseBlockBtn"></div>
                <ng-template #thenBlockBtn>
                    <div class="mb-3 col-7 col-md-10">
                        <p class="mt-1 ml-2 hyperlink-enabled">Fetching...</p>
                    </div>
                </ng-template>
                <ng-template #elseBlockBtn>
                    <div class="mb-3 col-7 col-md-10">
                        <p [ngClass]="{'hyperlink-enabled' : true}" class="mt-1 ml-2" (click)="fetchGoogleImages()">
                            Fetch Images from Google
                        </p>
                    </div>
                </ng-template>
            </div>
            <div [cdkDropListData]="mediaArr" class="d-flex flex-row flex-wrap" cdkDropList (cdkDropListDropped)="drop($event)">
                <div *ngFor="let media of mediaArr; index as i" cdkDrag>
                    <div *ngIf='media.type === "Image"' class="m-2">
                        <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                        <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                        <img width="195" height="195" [src]='media.url' alt='Image'>
                        <p>{{media.source}}</p>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row flex-wrap">
                <div *ngFor="let media of mediaArr; index as i">
                    <div *ngIf='media.type === "Video"' class="m-2">
                        <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                        <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                        <video id="my_video_1" class="video-js vjs-default-skin" width="195" height="195" controls preload="none" poster='assets/images/DishSquareIcon.png' data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                            <source
                                [src]="media.url"
                                type='video/mp4'
                            >
                            <!-- <source [src]="media.url" type='video/webm' /> -->
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mb-5">
            <div *ngIf="previews.length !== 0" class="alert alert-danger mt-2">
                Please upload the media first.
            </div>
            <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(foodProviderMediaForm.valid && previews.length === 0)}" [disabled]="!(foodProviderMediaForm.valid && previews.length === 0)" (click)="onSubmit()">
                NEXT
            </button>
        </div>
    </form>
</div>