/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "us-west-2"
// };


// export default awsmobile;
import Amplify, { Auth, Storage } from 'aws-amplify';

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:14f3a623-9916-4cc1-bece-9486565a885f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ayIHmieKL",
    "aws_user_pools_web_client_id": "6aatr86t366uuto3a9o2si4ull",
    "oauth": {
        "domain": "trofimo-dev-userpool.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "myapp://",
        "redirectSignOut": "myapp://",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS"
};
Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'trofimo-user-images',
            region: 'us-west-2'
        }
    }
});
export default awsmobile;
