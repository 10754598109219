import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FoodProviderService } from '../../services/food-provider.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private foodProviderService: FoodProviderService, private router: Router) { }

  ngOnInit(): void {
    this.foodProviderService.getMultipleConfig(environment.FILTER_CONFIG).subscribe((response) => {
      let formatConfigData = {};
      formatConfigData['DiningOptions'] = response['DiningOptions']
      formatConfigData['PaymentOptions'] = response['PaymentOptions']
      formatConfigData['QuickFilters'] = response['Quick Filters']
      formatConfigData['Cuisine'] = response['Cuisine']
      formatConfigData['EatingStyle'] = response['Eating Style']
      formatConfigData['DishClassification'] = response['DishClassification']
      formatConfigData['Tag'] = response['Tag']
      // console.log('formatConfigData', formatConfigData);
      this.foodProviderService.setConfigData(JSON.stringify(formatConfigData));
      })
  }
  navigateToEvent(){
  this.foodProviderService.setCurrentEvent({});
  this.router.navigate(['/evententry']);
  }
  navigateToList(){
    this.foodProviderService.setCurrentList({});
    this.router.navigate(['/listentry']);
    }
    navigateToRest(){
      this.foodProviderService.setCurrentRestaurant({});
      this.router.navigate(['/foodproviderstart']);
      }
}
