import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthGuard } from 'src/app/auth.guard'; 
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  constructor(private authService: AuthService,private authGaurd: AuthGuard, private router: Router, private localStorage: LocalStorageService) {
   }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngDoCheck(){
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  signOut(){
    this.authService.logout();
    this.router.navigate(['/auth']);
  }


}
