<div class="container mt-5">
    <!-- <hr class="line"> -->
    <form [formGroup]="eventForm" #formDir="ngForm">
        <div class="row">
            <div class="col-12 col-md-6 mb-5">
                <div class="mb-3 col-7 col-md-10">
                    <label for="eventName" class="form-label">
                        Event name
                    </label>
                    <input [readonly]="editMode" type="text" class="form-control" formControlName="name" id="eventName" placeholder="Enter event name" required>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger mt-2">
                        <div *ngIf="name.errors?.required">Event name is required.</div>
                    </div>
                    <span style="display:inline; color: rgb(248, 86, 10);margin-left: 10px; cursor: pointer;" routerLink="/eventsearch">
                        Edit existing event
                    </span>
                </div>
                <div class="input-group mb-3" style=" width: 520px">
                    <input type="text" style=" margin-right: 20px;" formControlName="hashtag" class="form-control" id="hashtag" placeholder="Enter hashtag">
                    <div class="input-group-append">
                        <button [ngClass]="{'disabled-btn-info': hashtag.invalid && (hashtag.dirty || hashtag.touched)}" [disabled]="hashtag.invalid && (hashtag.dirty || hashtag.touched)" class="btn btn-outline-secondary" type="button" (click)="validateHashTag()">
                            Check
                        </button>
                    </div>
                </div>
                <div style=" width: 520px" *ngIf="duplicateHashtag === true" class="alert alert-warning" role="alert">
                    Hashtag is already in use.
                </div>
                <div style=" width: 520px" *ngIf="duplicateHashtag === false" class="alert alert-success" role="alert">
                    Hashtag is ready to use.
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="subtitle" class="form-label">
                        Subtitle
                    </label>
                    <input type="text" class="form-control" formControlName="subtitle" id="subtitle" placeholder="Enter subtitle">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="subtitle" class="form-label">
                        Participants
                    </label>
                    <div *ngFor='let item of selectedParticipantsToDisplay; let i=index' class="row">
                        <div style="display: flex;">
                            <span class="form-check-label">{{item.name}} &nbsp;</span>
                            <p style="cursor: pointer;" (click)="removeParticipant(i)">
                                X
                            </p>
                        </div>
                    </div>
                    <input type="text" class="form-control" formControlName="participantInput" id="participantInput" (ngModelChange)="searchParticipants($event)" placeholder="Add participants">
                </div>
                <div *ngIf="participantSuggestions !== ''">
                    <li *ngFor="let record of participantSuggestions; index as i" class="list-group-item list-group-item-action" (click)="addParticipants(record)">{{record.suggestion}}</li>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="description" class="form-label">
                        Description
                    </label>
                    <textarea class="form-control" formControlName="description" style="height: 150px;" placeholder="Enter description"></textarea>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="email" class="form-label">
                        Email
                    </label>
                    <input type="text" class="form-control" formControlName="food_provider_email" id="food_provider_email" placeholder="Enter your email">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger mt-2">
                        <div *ngIf="email.errors?.required">Email is required.</div>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="website" class="form-label">
                        Website
                    </label>
                    <input type="text" formControlName="website" class="form-control" id="website" placeholder="Enter website">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="display_phone" class="form-label">
                        Display phone number
                    </label>
                    <input type="text" formControlName="display_phone" class="form-control" id="display_phone" placeholder="Enter phone number">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="address1" class="form-label">
                        Address
                    </label>
                    <input [readonly]="editMode" type="text" class="form-control" formControlName="address" id="address" placeholder="Enter address" required>
                    <div *ngIf="address.invalid && (address.dirty || address.touched)" class="alert alert-danger mt-2">
                        <div *ngIf="address.errors?.required">
                            Address is required.
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="city" class="form-label">
                        City
                    </label>
                    <input formControlName="location_city" type="text" class="form-control" id="city" placeholder="Enter city">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="state" class="form-label">
                        State
                    </label>
                    <input formControlName="location_state" type="text" class="form-control" id="state" placeholder="Enter state">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="country" class="form-label">
                        Country
                    </label>
                    <input formControlName="location_country" type="text" class="form-control" id="country" placeholder="Enter country">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="zip" class="form-label">
                        Zip Code
                    </label>
                    <input formControlName="location_zip" type="text" class="form-control" id="country" placeholder="Enter zip code">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="coordinates" class="form-label">
                        Coordinates
                    </label>
                    <input formControlName="coordinates" type="text" class="form-control" id="coordinates" placeholder="Ex 47.6731,-122.122158">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="budget" class="form-label">
                        Budget
                    </label>
                    <div class="d-flex flex-row">
                        <div *ngFor='let budgetType of budgetList; let i=index' class="col-3">
                            <div class="form-check">
                                <input class="form-check-input" formControlName="budget" type="radio" [value]="budgetType" [id]="'budgetType'+i">
                                <span class="px-2 form-check-label">{{budgetType}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="previews.length !== 0" class="alert alert-danger mt-2">
                    Please upload the media first.
                </div>
                <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
                <ng-template #thenBlockBtn>
                    <button class="save-btn-disabled mb-5" type="button" disabled>
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Loading...
                    </button>
                </ng-template>
                <ng-template #elseBlockBtn>
                    <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(eventForm.valid && !disableButton && previews.length === 0)}" [disabled]="!(eventForm.valid && !disableButton && previews.length === 0)" (click)="onSubmit()">
                        SAVE
                    </button>
                </ng-template>
            </div>
            <div class="col-12 col-md-6">
                <div class="col-12 col-md-12 mb-3">
                    <label for="mediatype" class="form-label">
                        Media Type
                    </label>
                    <div class="row">
                        <div *ngFor='let contentType of contentList; let i=index'>
                            <div class="form-check ">
                                <input class="form-check-input" formControlName="contentType" type="radio" [value]="contentType" [id]="'contentType'+i">
                                <span class="form-check-label">{{contentType}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-6">
                    <label for="media" class="form-label">
                        Media
                    </label>
                    <div class="mb-3">
                        <input #fileInput class="form-control" type="file" accept="image/png, image/jpeg" id="formFileMultiple" multiple (change)="selectFiles($event)">
                        <div id="column" *ngFor='let preview of previews; index as i'>
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 140px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeLocalImage(preview, i)" style="height: 28px;width:28px;cursor: pointer;margin-left: '9.1%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='Image'>
                            <img id="images" [src]="preview" class="preview" alt='Image'>
                        </div>
                    </div>
                    <button [ngClass]="{'btn-info': true, 'disabled-btn-info': name.invalid}" [disabled]="name.invalid" (click)="uploadImages()" *ngIf="previews.length !== 0 && !imagesUploading;" type="button" class="btn btn-info mb-3 col-7 col-md-10">
                        Upload media
                    </button>
                    <div *ngIf="imagesUploading; then uploadingImagesBtn"></div>
                    <ng-template #uploadingImagesBtn>
                        <button class="btn btn-info mb-3 col-7 col-md-10" type="button" disabled>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            uploading media
                        </button>
                    </ng-template>
                    <div class="mb-3 col-7 col-md-10">
                        <div *ngIf="name.invalid && previews.length !== 0" class="alert alert-danger mt-2">
                            Event name is mandatory for uploading media
                        </div>
                    </div>
                    <div class="mb-3 col-7 col-md-10">
                        <input type="text" class="form-control" id="imageUrl" formControlName="mediaInput" placeholder="Paste file URL">
                        <p style="color: rgba(255, 138, 59, 1); font-weight: 600; cursor: pointer;" class="mt-1 ml-2" (click)="addMedia()">
                            Add media
                        </p>
                    </div>
                    <div *ngIf="mediaInput.invalid && mediaInput.touched || mediaInput.errors" class="alert alert-danger mt-2">
                        Limit of the url exceeded.
                    </div>
                </div>
                <div class="d-flex flex-row flex-wrap">
                    <div *ngFor="let media of mediaArr; index as i">
                        <div *ngIf='media.type === "Image"' class="m-2">
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                            <img width="195" height="195" [src]='media.url' alt='Image'>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row flex-wrap">
                    <div *ngFor="let media of mediaArr; index as i">
                        <div *ngIf='media.type === "Video"' class="m-2">
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                            <video id="my_video_1" class="video-js vjs-default-skin" width="195" height="195" controls preload="none" poster='assets/images/DishSquareIcon.png' data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                                <source
                                    [src]="media.url"
                                    type='video/mp4'
                                >
                            </video>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <label for="start_date" class="form-label">
                        Start Date
                    </label>
                    <div class="input-group mb-3 mt-3">
                        <input type="date" formControlName="start_date" class="form-control" required>
                        <input type="time" formControlName="start_time" class="form-control" required>
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <label for="end_date" class="form-label">
                        End Date
                    </label>
                    <div class="input-group mb-6 mt-6">
                        <input type="date" formControlName="end_date" class="form-control" required>
                        <input type="time" formControlName="end_time" class="form-control" required>
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <label for="address1" class="form-label">
                        Categories
                    </label>
                    <div class="row">
                        <div *ngFor='let category of categories; let i=index' formArrayName="category" class="col-4">
                            <div class="form-check ">
                                <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="category.ConfigValue" [id]="'category.ConfigValue'+i">
                                <span class="form-check-label">{{category.ConfigValue}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>