<div class="container mt-5">
    <div *ngIf="progressFlowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container mt-5" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderhours">
                &nbsp; FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderattributes">
                &nbsp; FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor" routerLink="/dishlist">
                &nbsp; Dish
            </span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line">

    <form [formGroup]="dishInfoForm" #formDir="ngForm">
        <div class="row">
            <div class="col-12 col-md-6 mb-5">
                <div class="mb-3 col-7 col-md-10">
                    <label for="dishName" class="form-label">
                        Dish name
                    </label>
                    <input [readonly]="disableMode" type="text" class="form-control" formControlName="name" id="dishName" placeholder="Enter dish name" required>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger mt-2">
                        <div *ngIf="name.errors?.required">
                            Dish name is required.
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="dishName" class="form-label">
                        Dish type
                    </label>
                    <div>
                        <div *ngFor='let dishType of dishTypeList; let i=index'>
                            <div class="form-check ">
                                <input class="form-check-input" formControlName="dishType" type="radio" [value]="dishType" [id]="'dishType'+i">
                                <span class="form-check-label">{{dishType}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="description" class="form-label">
                        Description
                    </label>
                    <textarea class="form-control" formControlName="description" style="height: 150px;" placeholder="Enter description"></textarea>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="price" class="form-label">
                        Price ($)
                    </label>
                    <input type="text" formControlName="price" class="form-control" id="price" placeholder="Enter Price">
                    <div *ngIf="price.invalid && price.touched || price.errors" class="alert alert-danger mt-2">
                        No special characters are allowed.
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-5">
                    <label for="address1" class="form-label">
                        Classification
                    </label>
                    <div class="row">
                        <div *ngFor='let item of dishClassificationList; let i=index' formArrayName="dish_classification" class="col-2">
                            <div class="form-check ">
                                <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="item.ConfigValue" [id]="'dishClassification_item.ConfigValue'+i">
                                <span class="form-check-label">{{item.ConfigValue}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-5">
                    <label for="address1" class="form-label">
                        Eating styles
                    </label>
                    <div class="row">
                        <div *ngFor='let item of eatingStyleList; let i=index' formArrayName="eating_styles" class="col-2">
                            <div class="form-check ">
                                <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="item.ConfigValue" [id]="'eatingStyle_item.ConfigValue'+i">
                                <span style="width: 500px;" class="form-check-label">{{item.ConfigValue}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="ingredients" class="form-label">
                        Ingredients​​
                    </label>
                    <input type="text" class="form-control" formControlName="ingredients" id="ingredients" placeholder="Enter ingredients using comma seperated words">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="serving_size" class="form-label">
                        Serving size (g)​​
                    </label>
                    <input type="text" class="form-control" formControlName="serving_size" id="serving_size" placeholder="Enter serving size">
                    <div *ngIf="servingSize.invalid && servingSize.touched || servingSize.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="calories" class="form-label">
                        Calories (kcal)
                    </label>
                    <input type="text" formControlName="calories" class="form-control" id="calories" placeholder="Enter Calories">
                    <div *ngIf="calories.invalid && calories.touched || calories.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="fat" class="form-label">
                        Fat (g)
                    </label>
                    <input type="text" formControlName="fat" class="form-control" id="fat" placeholder="Enter Fats">
                    <div *ngIf="fat.invalid && fat.touched || fat.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="cholesterol" class="form-label">
                        Cholesterol (g)​
                    </label>
                    <input type="text" formControlName="cholesterol" class="form-control" id="cholesterol" placeholder="Enter Cholesterol">
                    <div *ngIf="cholesterol.invalid && cholesterol.touched || cholesterol.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="carbohydrates" class="form-label">
                        Carbohydrates (g)​
                    </label>
                    <input type="text" formControlName="carbohydrates" class="form-control" id="carbohydrates" placeholder="Enter Carbohydrates">
                    <div *ngIf="carbohydrates.invalid && carbohydrates.touched || carbohydrates.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="fiber" class="form-label">
                        Fiber (g)
                    </label>
                    <input type="text" formControlName="fiber" class="form-control" id="fiber" placeholder="Enter Fiber">
                    <div *ngIf="fiber.invalid && fiber.touched || fiber.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="sugar" class="form-label">
                        Sugar (g)​
                    </label>
                    <input type="text" formControlName="sugar" class="form-control" id="sugar" placeholder="Enter Sugar">
                    <div *ngIf="sugar.invalid && sugar.touched || sugar.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="protein" class="form-label">
                        Protein (g)​
                    </label>
                    <input type="text" formControlName="protein" class="form-control" id="protein" placeholder="Enter Protein">
                    <div *ngIf="protein.invalid && protein.touched || protein.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="sodium" class="form-label">
                        Sodium (mg)​
                    </label>
                    <input type="text" formControlName="sodium" class="form-control" id="sodium" placeholder="Enter Sodium">
                    <div *ngIf="sodium.invalid && sodium.touched || sodium.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="iron" class="form-label">
                        Iron​ (mg)​
                    </label>
                    <input type="text" formControlName="iron" class="form-control" id="iron" placeholder="Enter Iron">
                    <div *ngIf="iron.invalid && iron.touched || iron.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="vitamin_a" class="form-label">
                        Vitamin A​ (mcg)​
                    </label>
                    <input type="text" formControlName="vitamin_a" class="form-control" id="vitamin_a" placeholder="Enter Vitamin A">
                    <div *ngIf="vitamin_a.invalid && vitamin_a.touched || vitamin_a.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="vitamin_c" class="form-label">
                        Vitamin C​ (mg)​
                    </label>
                    <input type="text" formControlName="vitamin_c" class="form-control" id="vitamin_c" placeholder="Enter Vitamin C">
                    <div *ngIf="vitamin_c.invalid && vitamin_c.touched || vitamin_c.errors" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <label for="nutrition" class="form-label">
                    Nutrition
                </label>
                <div *ngFor='let item of nutrition | keyvalue; let i=index' class="row">
                    <div style="display: flex;">
                        <span class="form-check-label">{{item.key + '--' + item.value}} &nbsp;</span>
                        <p style="cursor: pointer;" (click)='removeNutrition(item.key)'>
                            X
                        </p>
                    </div>
                </div>
                <div class="input-group mb-3" style=" width: 350px">
                    <input style=" margin-right: 20px;" type="text" formControlName="nutritionName" class="form-control" id="nutritionName" placeholder="Enter Name">
                    <input type="text" style=" margin-right: 20px;" formControlName="nutritionValue" class="form-control" id="nutritionValue" placeholder="Enter Value">
                    <div class="input-group-append">
                        <button [ngClass]="{'disabled-btn-info': nutritionValue.invalid && (nutritionValue.dirty || nutritionValue.touched)}" [disabled]="nutritionValue.invalid && (nutritionValue.dirty || nutritionValue.touched)" class="btn btn-outline-secondary" type="button"
                            (click)="addNutrition()">
                            Add
                        </button>
                    </div>
                    <div *ngIf="nutritionValue.invalid && (nutritionValue.dirty || nutritionValue.touched)" class="alert alert-danger mt-2">
                        Only numbers are allowed.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <input formControlName="popular_item" class="form-check-input" type="checkbox" value id="flexCheckDefault">
                    <label for="popular_item" class="form-label">
                        &nbsp; Popular item
                    </label>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="recommended_bev_pairing" class="form-label">
                        Recommended bev pairing
                    </label>
                    <div class="col-md-12">
                        <mat-form-field class='col-12' appearance="fill">
                            <mat-label>Select any dish</mat-label>
                            <mat-select [formControl]="recommended_bev_pairing" multiple>
                                <mat-select-trigger>
                                    {{recommended_bev_pairing.value!=null && recommended_bev_pairing.value?.length !== 0 ? recommended_bev_pairing.value[0].fields?.name : ''}}
                                    <span *ngIf="recommended_bev_pairing.value?.length > 1" class="example-additional-selection">
                                        (+{{recommended_bev_pairing.value.length - 1}}
                                        {{recommended_bev_pairing.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let dish of recommendedDishes" [value]="dish">
                                    {{dish.fields.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="recommended_side_and_dish_pairing" class="form-label">
                        Recommended side & dish pairing​
                    </label>
                    <div class="col-md-12">
                        <mat-form-field class='col-12' appearance="fill">
                            <mat-label>Select any dish</mat-label>
                            <mat-select [formControl]="recommended_side_and_dish_pairing" multiple>
                                <mat-select-trigger>
                                    {{recommended_side_and_dish_pairing.value != null && recommended_side_and_dish_pairing.value?.length !== 0 ? recommended_side_and_dish_pairing.value[0].fields?.name : ''}}
                                    <span *ngIf="recommended_side_and_dish_pairing.value?.length > 1" class="example-additional-selection">
                                        (+{{recommended_side_and_dish_pairing.value.length - 1}}
                                        {{recommended_side_and_dish_pairing.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let dish of recommendedDishes" [value]="dish">
                                    {{dish.fields.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <label for="add_ons" class="form-label">
                    Recommended Addon's
                </label>
                <div class="col-md-10">
                    <mat-form-field class='w-100' appearance="fill">
                        <mat-label>Select any addon</mat-label>
                        <mat-select [formControl]="add_ons" multiple>
                            <mat-select-trigger>
                                {{add_ons.value!=null && add_ons.value?.length !== 0 ? add_ons.value[0].fields.name : ''}}
                                <span *ngIf="add_ons.value?.length > 1" class="example-additional-selection">
                                    (+{{add_ons.value.length - 1}}
                                    {{add_ons.value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let addon of recommendedAddons" [value]="addon">
                                {{addon.fields.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="previews.length !== 0" class="alert alert-danger mt-2">
                    Please upload the media first.
                </div>
                <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
                <ng-template #thenBlockBtn>
                    <button class="save-btn-disabled mb-5" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </ng-template>
                <ng-template #elseBlockBtn>
                    <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(dishInfoForm.valid && previews.length === 0)}" [disabled]="!(dishInfoForm.valid && previews.length === 0)" (click)="onSubmit()">
                        SAVE
                    </button>
                </ng-template>
            </div>
            <div class="col-12 col-md-6">
                <div class="col-12 col-md-12 mb-3">
                    <label for="mediatype" class="form-label">
                        Media Type
                    </label>
                    <div class="row">
                        <div *ngFor='let contentType of contentList; let i=index'>
                            <div class="form-check ">
                                <input class="form-check-input" formControlName="contentType" type="radio" [value]="contentType" [id]="'contentType'+i">
                                <span class="form-check-label">{{contentType}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-6">
                    <label for="media" class="form-label">
                        Media
                    </label>
                    <div class="mb-3">
                        <input #fileInput class="form-control" type="file" accept="image/png, image/jpeg" id="formFileMultiple" multiple (change)="selectFiles($event)">
                        <div id="column" *ngFor='let preview of previews; index as i'>
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 140px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeLocalImage(preview)" style="height: 28px;width:28px;cursor: pointer;margin-left: '9.1%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='Image'>
                            <img id="images" [src]="preview" class="preview" alt='Image'>
                        </div>
                    </div>
                    <button [ngClass]="{'btn-info': true, 'disabled-btn-info': !dishInfoForm.valid}" [disabled]="!dishInfoForm.valid" (click)="uploadImages()" *ngIf="previews.length !== 0 && !imagesUploading;" type="button" class="btn btn-info mb-3 col-7 col-md-10">
                        Upload media
                    </button>
                    <div *ngIf="imagesUploading; then uploadingImagesBtn"></div>
                    <ng-template #uploadingImagesBtn>
                        <button class="btn btn-info mb-3 col-7 col-md-10" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            uploading media
                        </button>
                    </ng-template>
                    <div class="mb-3 col-7 col-md-10">
                        <div *ngIf="!dishInfoForm.valid && previews.length !== 0" class="alert alert-danger mt-2">
                            Dish name is mandatory for uploading media
                        </div>
                    </div>
                    <div class="mb-3 col-7 col-md-10">
                        <input type="text" class="form-control" id="imageUrl" formControlName="mediaInput" placeholder="Paste file URL">
                        <p [ngClass]="{'hyperlink-enabled' : mediaInput.value !== '', 'hyperlink-disabled' : mediaInput.value === ''}" class="mt-1 ml-2" (click)="addMedia()">
                            Add media
                        </p>
                    </div>
                    <div *ngIf="mediaInput.invalid && mediaInput.touched || mediaInput.errors" class="alert alert-danger mt-2">
                        Limit of the url exceeded.
                    </div>
                </div>
                <div>
                    <div *ngIf="fetchImages; then thenBlockLink else elseBlockLink"></div>
                    <ng-template #thenBlockLink>
                        <div class="mb-3 col-7 col-md-10">
                            <p class="mt-1 ml-2 hyperlink-enabled">
                                Fetching...
                            </p>
                        </div>
                    </ng-template>
                    <ng-template #elseBlockLink>
                        <div class="mb-3 col-7 col-md-10">
                            <p [ngClass]="{'hyperlink-enabled' : name.value !== '', 'hyperlink-disabled' : name.value === ''}" class="mt-1 ml-2" (click)="fetchGoogleImages()">
                                Fetch Images from Google
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div [cdkDropListData]="mediaArr" class="d-flex flex-row flex-wrap" cdkDropList (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let media of mediaArr; index as i" cdkDrag>
                        <div *ngIf='media.type === "Image"' class="m-2">
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                            <img width="195" height="195" [src]='media.url' alt='Image'>
                            <p>{{media.source}}</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row flex-wrap">
                    <div *ngFor="let media of mediaArr; index as i">
                        <div *ngIf='media.type === "Video"' class="m-2">
                            <img style="height: 60px;width: 60px; cursor: pointer;margin-left: 135px;position: absolute;" src='assets/images/heartIcon_overlay.png' alt='Image'>
                            <img class="brightness" (click)="removeImage(media)" style="height: 28px;width:28px;cursor: pointer;margin-left: '8.5%';margin-top: '0.4%'; position: absolute;color: aliceblue;" src='assets/images/cancelCircle.png' alt='image'>
                            <video id="my_video_1" class="video-js vjs-default-skin" width="195" height="195" controls preload="none" poster='assets/images/DishSquareIcon.png' data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                                <source [src]="media.url" type='video/mp4' />
                                <!-- <source [src]="media.url" type='video/webm' /> -->
                            </video>
                        </div>
                    </div>
                </div>
                <label for="Tags" class="form-label">
                    Tags
                </label>
                <div class="row">
                    <div *ngFor='let item of tags ; let i=index' class="col-4 mb-2">
                        <div class="form-check">
                            <span class="form-check-label">{{item.tagname}} &nbsp;</span>
                            <span style="cursor: pointer;" (click)='removeTag(i)'>
                                X
                            </span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" style=" width: 350px">
                    <input style=" margin-right: 20px;" type="text" formControlName="tagName" class="form-control" id="Tags" placeholder="Enter Tag Name" (ngModelChange)="tagSuggestions($event)">
                </div>
                <div *ngIf="tagSuggestionsOnChange.length >= 0">
                    <li style="width: 350px" *ngFor="let record of tagSuggestionsOnChange; index as i" class="list-group-item list-group-item-action" (click)="addTag(record)">{{record}}</li>
                </div>
                <div *ngIf="(tagName.value !== '' && tagSuggestionsOnChange.length === 0)">
                    <p style="cursor: pointer;color: #ff8a3b;" (click)='addTag(tagName.value)'>
                        add tag name <strong style="color: black;">{{tagName.value}}</strong>
                    </p>
                </div>

            </div>
        </div>
    </form>
</div>