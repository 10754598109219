<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">Start</span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">Select</span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">FP Info</span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5">&nbsp; FP Location</span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line" style="left: 0; right: 0">
    <form [formGroup]="foodProviderLocationForm" class="mb-5">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="mb-3 col-7 col-md-10">
                    <label for="address1" class="form-label">Address 1</label>
                    <input [readonly]="flowType==='edit'" formControlName="location_address_1" type="text" class="form-control" id="address1"
                        placeholder="Input address 1">
                    <div *ngIf="location1.invalid && (location1.dirty || location1.touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="location1.errors?.required">
                            Address 1 is required.
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="address2" class="form-label">Address 2</label>
                    <input formControlName="location_address_2" type="text" class="form-control" id="address2" placeholder="Input address 2">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="address3" class="form-label">Address 3</label>
                    <input formControlName="location_address_3" type="text" class="form-control" id="address3" placeholder="Input address 3">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="city" class="form-label">City</label>
                    <input formControlName="location_city" type="text" class="form-control" id="city" placeholder="Input city">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="state" class="form-label">State</label>
                    <input formControlName="location_state" type="text" class="form-control" id="state" placeholder="Input state">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="country" class="form-label">Country</label>
                    <input formControlName="location_country" type="text" class="form-control" id="country" placeholder="Input country">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="coordinates" class="form-label">Coordinates</label>
                    <input formControlName="coordinates" type="text" class="form-control" id="coordinates" placeholder="Ex 47.6731,-122.122158">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="zipCode" class="form-label">Zip code</label>
                    <input formControlName="location_zip_code" type="text" class="form-control" id="zipCode" placeholder="Input zip code">
                </div>
            </div>
        </div>
        <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !foodProviderLocationForm.valid}" [disabled]="!foodProviderLocationForm.valid" (click)="saveRestaurant()">NEXT</button>
    </form>
</div>