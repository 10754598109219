import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import debounce from 'lodash.debounce';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.css']
})

export class EventSearchComponent implements OnInit {
  eventSearchForm: any;
  suggestionItem: any;
  eventFullResponse: any;
  searchTerm: any;
  enableButton: boolean = false;
  noResultsFound: boolean = false;
  currentEvent: any;

  constructor(private foodProviderService: FoodProviderService, private router: Router) {
    this.searchEvent = debounce(this.searchEvent, 500);
    this.eventSearchForm = new FormGroup({
      searchTerm: new FormControl(''),
    })
  }

  ngOnInit(): void {
  }
  searchEvent(searchTerm: any) {
    //console.log(searchTerm);
    this.searchTerm = searchTerm;
    this.noResultsFound = false;
    if (searchTerm !== '' && searchTerm.charAt(0) !== '#') {
      this.foodProviderService.getEventSuggestions(searchTerm).subscribe((response) => {
        //console.log('getEventSuggestions', response)
        this.suggestionItem = response.suggest.suggestions
      })
    }
  }
  onClickEventSuggestion(item: any) {
    this.eventSearchForm.patchValue({
      searchTerm: item.suggestion
    });
    this.suggestionItem = ''
    this.foodProviderService.getItemById(item.id).subscribe((response) => {
      //console.log('getItemByIdResponse', response.hits.hit[0])
      if (response.hits.hit !== undefined && response.hits.hit[0] !== undefined) {
        this.currentEvent = response.hits.hit[0];
        this.enableButton = true;
      } else {
        this.noResultsFound = true;
      }
    })
  }
  editEvent(){
    this.foodProviderService.setCurrentEvent(this.currentEvent)
    this.router.navigate(['/evententry', { flowType: 'edit' }]);
  }
}
