<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5">&nbsp; FP Info</span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line" style="left: 0; right: 0">
    <form [formGroup]="foodProviderInfoForm">
        <div class="row">
            <div class="col-12 col-md-6 mb-5">
                <div class="mb-3 col-7 col-md-10">
                    <label for="foodProviderName" class="form-label">Food provider name</label>
                    <input [readonly]="flowType==='edit'" type="text" class="form-control" formControlName="name" id="foodProviderName" placeholder="Input food provider name">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="subtitle" class="form-label">
                        Subtitle
                    </label>
                    <input type="text" class="form-control" formControlName="subtitle" id="subtitle" placeholder="Input subtitle">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="description" class="form-label">
                        Description
                    </label>
                    <textarea class="form-control" formControlName="description" style="height: 150px;" placeholder="Enter description"></textarea>

                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="website" class="form-label">
                        Website
                    </label>
                    <input type="text" formControlName="website" class="form-control" id="website" placeholder="Enter website">
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="phoneNumber" class="form-label">
                        Phone number
                    </label>
                    <input type="text" formControlName="phone" class="form-control" id="phoneNumber" placeholder="Enter phone number">
                    <div *ngIf="phonenumber.invalid && phonenumber.touched || phonenumber.errors" class="alert alert-danger mt-2">
                        Enter valid phone number.
                    </div>
                </div>

                <div class="mb-3 col-7 col-md-10">
                    <label for="display_phone" class="form-label">
                        Display phone number
                    </label>
                    <input type="text" formControlName="display_phone" class="form-control" id="display_phone" placeholder="Enter phone number">
                    <div *ngIf="display_phone.invalid && display_phone.touched || display_phone.errors" class="alert alert-danger mt-2">
                        Enter valid phone number.
                    </div>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="placeId" class="form-label">
                        Place id
                    </label>
                    <input type="text" formControlName="place_id" class="form-control" id="placeId" placeholder="Enter place id">
                </div>

                <div class="mb-3 col-7 col-md-10">
                    <label for="provider_id" class="form-label">
                        Provider id
                    </label>
                    <input type="text" formControlName="provider_id" class="form-control" id="provider_id" placeholder="Enter provider id">
                </div>

                <div class="mb-3 col-7 col-md-10">
                    <label for="provider_url" class="form-label">
                        Provider url
                    </label>
                    <input type="text" formControlName="provider_url" class="form-control" id="provider_url" placeholder="Enter provider url">
                </div>
                <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(foodProviderInfoForm.valid)}" [disabled]="!(foodProviderInfoForm.valid)" (click)="onSubmit()">
                    NEXT
                </button>
            </div>
        </div>
    </form>
</div>