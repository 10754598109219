<div class="container mt-5">
    <div style="display: flex; align-items: center;">
        <div class="circle-selected"></div>
        <span class="fs-5 cursor" routerLink="/foodproviderstart">&nbsp; Start</span>
        <span class="fs-5">-----</span>
        <div class="circle"></div>
        <span class="fs-5">&nbsp; Select</span>
    </div>
    <hr class="line" style="left: 0; right: 0">
    <form>
        <div class="mb-3 col-4">
          <label for="foodProviderName" class="form-label">Modify existing entry</label>
          <ul class="list-group">
            <li *ngFor="let restauarant of AWSCSRestauarants; index as i" class="list-group-item list-group-item-action" (click)="onClick(restauarant)">{{restauarant.fields.name}}, {{restauarant.fields.location_address_1}}, {{restauarant.fields.location_city}}</li>
          </ul>
          <div *ngIf="isLoading" class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div *ngIf="!isLoading && AWSCSRestauarants.length == 0" class="alert alert-warning" role="alert">
            No results found.
          </div>
        </div>
        <div class="mb-3 col-4">
          <label for="foodProviderCity" class="form-label">Add new entry</label>
          <ul class="list-group">
            <li *ngFor="let restauarant of yelpRestaurants; index as i" [ngClass]="{'list-group-item': true, 'list-group-item-action': true, 'disabled': listLoading}" (click)="onClickYelpRestaurant(restauarant)">
              <span *ngIf="listLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{restauarant.name}}, {{restauarant.location.address1}}, {{restauarant.location.city}}
            </li>
          </ul>
          <div *ngIf="isLoadingYelp" class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div *ngIf="!isLoadingYelp && yelpRestaurants.length == 0" class="alert alert-warning" role="alert">
            No results found.
          </div>
        </div>

        <div class="mb-3 col-4">
          <label for="foodProviderCity" class="form-label">Create from scratch</label>
          <ul class="list-group">
            <li class="list-group-item list-group-item-action" (click)="onClick({})">Create</li>
          </ul>
        </div>
      </form>
</div>