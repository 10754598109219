<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderhours">
                &nbsp; FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderattributes">
                &nbsp; FP Attributes
            </span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line">
    <form [formGroup]="foodProviderAttributes" class="mb-5">
        <div class="row">
            <div class="col-12 col-md-12 mb-5">
                <label for="address1" class="form-label">
                    Features
                </label>
                <div class="row">
                    <div *ngFor='let feature of features; let i=index' class="col-2" formArrayName="amenities">
                        <div class="form-check ">
                            <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="feature.ConfigValue" [id]="'feature.ConfigValue'+i">
                            <span class="form-check-label">{{feature.ConfigValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 mb-5">
                <label for="address1" class="form-label">
                    Dining options
                </label>
                <div class="row">
                    <div *ngFor='let diningOption of diningOptions; let i=index' class="col-2" formArrayName="dining_options">
                        <div class="form-check ">
                            <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="diningOption.ConfigValue" [id]="'diningOption.ConfigValue'+i">
                            <span class="form-check-label">{{diningOption.ConfigValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 mb-5">
                <label for="address1" class="form-label">
                    Payment options
                </label>
                <div class="row">
                    <div *ngFor='let paymentOption of paymentOptions; let i=index' formArrayName="payment_options" class="col-2">
                        <div class="form-check ">
                            <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="paymentOption.ConfigValue" [id]="'paymentOption.ConfigValue'+i">
                            <span class="form-check-label">{{paymentOption.ConfigValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 mb-5">
                <label for="address1" class="form-label">
                    Budget
                </label>
                <div class="row">
                    <div *ngFor='let budget of budgetList; let i=index' class="col-2">
                        <div class="form-check ">
                            <input class="form-check-input" type="radio" [value]="budget" formControlName="budget" [id]="'budget'+i">
                            <span class="form-check-label">{{budget}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 mb-5">
                <label for="address1" class="form-label">
                    Categories
                </label>
                <div class="row">
                    <div *ngFor='let category of categories; let i=index' formArrayName="category" class="col-2">
                        <div class="form-check ">
                            <input class="form-check-input" [formControlName]="i" type="checkbox" [value]="category.ConfigValue" [id]="'category.ConfigValue'+i">
                            <span class="form-check-label">{{category.ConfigValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <label for="Tags" class="form-label">
                    Tags
                </label>
                <div class="row">
                    <div *ngFor='let item of tags ; let i=index' class="col-2 mb-2">
                        <div class="form-check">
                            <span class="form-check-label">{{item.tagname}} &nbsp;</span>
                            <span style="cursor: pointer;" (click)='removeTag(i)'>
                                X
                            </span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" style="width: 350px">
                    <input style=" margin-right: 20px;" type="text" formControlName="tagName" class="form-control" id="tagName" placeholder="Enter Tag Name" (ngModelChange)="tagSuggestions($event)">
                </div>
                <div *ngIf="tagSuggestionsOnChange.length >= 0">
                    <li style="width: 350px" *ngFor="let record of tagSuggestionsOnChange; index as i" class="list-group-item list-group-item-action" (click)="addTag(record)">{{record}}</li>
                </div>
                <div *ngIf="(tagName.value !== '' && tagSuggestionsOnChange.length === 0)">
                    <p style="cursor: pointer;color: #ff8a3b;" (click)='addTag(tagName.value)'>
                        add tag name <strong style="color: black;">{{tagName.value}}</strong>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
            <ng-template #thenBlockBtn>
                <button class="save-btn-disabled mb-5" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </ng-template>
            <ng-template #elseBlockBtn>
                <button type="submit" class="save-btn mb-5" (click)='onClickBtn()'>
                    SUBMIT
                </button>
            </ng-template>
        </div>
    </form>
</div>