import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { FoodProviderStartComponent } from './core/components/food-provider-start/food-provider-start.component';
import { FoodProviderSelectComponent } from './core/components/food-provider-select/food-provider-select.component';
import { FoodProviderInfoComponent } from './core/components/food-provider-info/food-provider-info.component';
import { FoodProviderLocationComponent } from './core/components/food-provider-location/food-provider-location.component';
import { FoodProviderHoursComponent } from './core/components/food-provider-hours/food-provider-hours.component';
import { FoodProviderAttributesComponent } from './core/components/food-provider-attributes/food-provider-attributes.component';
import { DishEntryComponent } from './core/components/dish-entry/dish-entry.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth.guard';
import { DishListComponent } from './core/components/dish-list/dish-list.component';
import { FoodMenuComponent } from './core/components/food-menu/food-menu.component';
import { FoodMenuFormComponent } from './core/components/food-menu-form/food-menu-form.component';
import { FoodProviderMediaComponent } from './core/components/food-provider-media/food-provider-media.component';
import { EventEntryComponent } from './core/components/event-entry/event-entry.component';
import { EventSearchComponent } from './core/components/event-search/event-search.component';
import { ListEntryComponent } from './core/components/list-entry/list-entry.component';
import { ListSearchComponent } from './core/components/list-search/list-search.component';
import { AdminApprovalComponent } from './core/components/admin-approval/admin-approval.component';
import { ReviewTagComponent } from './core/components/review-tag/review-tag.component';
import { AddTagsComponent } from './core/components/add-tags/add-tags.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'listsearch',
    component: ListSearchComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'evententry',
    component: EventEntryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'eventsearch',
    component: EventSearchComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'listentry',
    component: ListEntryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodproviderstart',
    component: FoodProviderStartComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  { path: 'auth', component: AuthComponent },
  {
    path: 'foodproviderselect',
    component: FoodProviderSelectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodproviderinfo',
    component: FoodProviderInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodproviderlocation',
    component: FoodProviderLocationComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodprovidermedia',
    component: FoodProviderMediaComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodproviderhours',
    component: FoodProviderHoursComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'foodproviderattributes',
    component: FoodProviderAttributesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'dishentry',
    component: DishEntryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'dishlist',
    component: DishListComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'menulist',
    component: FoodMenuComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'menuform',
    component: FoodMenuFormComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'adminapproval/:username/:createddate',
    component: AdminApprovalComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'reviewtag',
    component: ReviewTagComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  {
    path: 'addtags',
    component: AddTagsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TrofimoStaff'] },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
