<div class="container mt-5">
    <form [formGroup]="listEntryForm" #formDir="ngForm">
        <div class="mb-3 col-3">
            <label for="listName" class="form-label">List Name</label>
            <input (focus)="locationInputFocus = false" required type="text" class="form-control"
                formControlName="listName" id="listName" placeholder="Enter list name">
            <span class="edit-link-enabled" (click)="navigateToSearch()">
                Edit existing list
            </span>
        </div>
        <div class="mb-3 col-3">
            <label for="scope" class="form-label">Scope</label>
            <input (focus)="locationInputFocus = true" required type="text" class="form-control" formControlName="scope"
                id="scope" placeholder="Input location" (ngModelChange)="searchLocation($event)">
            <div *ngIf="locationSuggestions !== '' && locationInputFocus">
                <li *ngFor="let record of locationSuggestions; index as i"
                    class="list-group-item list-group-item-action" (click)="onClickLocation(record.description)">
                    {{record.description}}</li>
            </div>
        </div>
        <p *ngIf="locationInputFocus && locationSuggestions.length === 0">Start typing location and select from the list
        </p>
        <div class="mb-3 col-3">
            <label for="description" class="form-label">List Description</label>
            <input (focus)="locationInputFocus = false" type="text" class="form-control" formControlName="description"
                id="description" placeholder="Input list description">
        </div>
        <div class="col-12 col-md-12">
            <label for="Tags" class="form-label">
                Tags
            </label>
            <div class="row">
                <div *ngFor='let item of tags ; let i=index' class="col-2 mb-2">
                    <div class="form-check">
                        <span class="form-check-label">{{item}} &nbsp;</span>
                        <span style="cursor: pointer;" (click)='removeTag(i)'>
                            X
                        </span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3" style="width: 350px">
                <input style=" margin-right: 20px;" type="text" formControlName="tagName" class="form-control"
                    id="tagName" placeholder="Enter Preferred Tags" (ngModelChange)="tagSuggestions($event)">
            </div>
            <div *ngIf="tagSuggestionsOnChange.length >= 0">
                <li style="width: 350px" *ngFor="let record of tagSuggestionsOnChange; index as i"
                    class="list-group-item list-group-item-action" (click)="addTag(record)">{{record}}</li>
            </div>
            <div *ngIf="(tagName.value !== '' && tagSuggestionsOnChange.length === 0)">
                <p style="cursor: pointer;color: #ff8a3b;" (click)='addTag(tagName.value)'>
                    add tag name <strong style="color: black;">{{tagName.value}}</strong>
                </p>
            </div>
        </div>
        <div class="col-12 col-md-12">
            <label for="Selected Items" class="form-label">
                Selected Items
            </label>
            <div class="row">
                <div *ngFor='let item of selectedEntries ; let i=index'>
                    <div class="form-check">
                        <span class="form-check-label">{{item}} &nbsp;</span>
                        <span style="cursor: pointer;" (click)='removeItem(i)'>
                            X
                        </span>
                    </div>
                </div>
            </div>
            <div style="width: 220px;" *ngIf="!isLoading && selectedEntries.length === 0" class="alert alert-primary" role="alert">
                No Items selected yet.
            </div>
        </div>
        <div class="mb-3 col-3">
            <label for="name" class="form-label">Search Input</label>
            <input type="text" class="form-control" formControlName="searchInput" id="entryInput"
                placeholder="Input item name" (ngModelChange)="searchEntries($event)">
        </div>
        <div class="mb-3 col-3">
            <label for="name" class="form-label">Items</label>
        </div>
        <div class="col-md-5 mb-3">
            <ng-multiselect-dropdown [placeholder]="'Select Items'" [data]="itemSuggestions"
                (onSelect)="onItemSelect($event)" [settings]="dropdownSettings">
            </ng-multiselect-dropdown>
        </div>
        <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
        <ng-template #thenBlockBtn>
            <button class="save-btn-disabled mb-5" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
            </button>
        </ng-template>
        <ng-template #elseBlockBtn>
            <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(enableButton && listEntryForm.valid)}"
                [disabled]="!(enableButton && listEntryForm.valid)" (click)="onSubmit()">SAVE</button>
        </ng-template>
    </form>
</div>