import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { FoodProviderService } from '../../services/food-provider.service';
import debounce from 'lodash.debounce';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-list-entry',
  templateUrl: './list-entry.component.html',
  styleUrls: ['./list-entry.component.css']
})
export class ListEntryComponent implements OnInit {
  listEntryForm: any;
  enableButton: boolean = false;
  currentList: any;
  itemSuggestions: any = [];
  selectedEntries: any = [];
  locationSuggestions: any = [];
  stopLocSuggestions: boolean = false;
  isLoading: boolean = false;
  editList: boolean = false;
  locationInputFocus: boolean = false;
  tags: any = [];
  tagsObj: any = {};
  tagSuggestionsOnChange: any = [];
  tagSuggestionsList: Array<any> = [];
  dropdownSettings = {};
  constructor(private _Activatedroute: ActivatedRoute, private foodProviderService: FoodProviderService, private router: Router) {
    this.searchEntries = debounce(this.searchEntries, 500);
    this.searchLocation = debounce(this.searchLocation, 500);
    let configData = this.foodProviderService.getConfigData();
    this.tagSuggestionsList = JSON.parse(configData).Tag;
    this.listEntryForm = new FormGroup({
      listName: new FormControl(''),
      scope: new FormControl(''),
      description: new FormControl(''),
      entryInput: new FormControl(''),
      searchInput: new FormControl(''),
      tagName: new FormControl(''),
      curatedListFor: new FormControl(''),
    })
  }
  onItemSelect(item: any) {
    //console.log('item', item);
    this.selectedEntries.push(item.id)
  }
  tagSuggestions(tagname: string) {
    if (tagname !== '') {
      this.tagSuggestionsOnChange = this.tagSuggestionsList
        .filter(item => item.ConfigValue.toLowerCase().startsWith(tagname.toLowerCase()))
        .map(item => item.ConfigValue);
    } else {
      this.tagSuggestionsOnChange = [];
    }
  }
  
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    if (this._Activatedroute.snapshot.params.flowType === 'edit') {
      this.editList = true;
    } else {
      this.editList = false;
    }
    this.currentList = this.foodProviderService.getCurrentList();
    console.log('this.currentList', this.currentList);
    if (this.currentList !== undefined) {
      this.enableButton = true;
      const { CuratedListFor, Name, Ids, Description, Tags, Scope, ...rest } = this.currentList;
      
      const tempList = {
        curatedListFor: CuratedListFor,
        listName: Name,
        description: Description,
        scope: Scope,
        ...rest
      };
    
      if (Ids) {
        this.selectedEntries = Ids;
      }
      if (Tags) {
        this.tags = Tags;
      }
      console.log('tempList', tempList);
      this.listEntryForm.patchValue(tempList);
    }
    
  }
  formatData(data: []) {
    return data.map(({ id, fields }) => {
      let name: string = fields['name'];
      const recordType = fields['record_type'];
  
      if (recordType === 'Restaurant') {
        name = `${fields['name']}---${fields['location_city']}`;
      } else if (recordType === 'Dish') {
        name = `${fields['name']}---${fields['food_provider_and_location']}`;
      }
  
      return {
        id: id,
        name: name,
      };
    });
  }
  
  removeTag(key: number) {
    this.tags.splice(key, 1);
  }
  removeItem(key: number) {
    this.selectedEntries.splice(key, 1);
  }
  addTag(tagName: string) {
    if (tagName !== '') {

      if (!this.tags?.includes(tagName)) {
        this.tags.push(tagName);
      } else {
        alert('Tag already added.');
      }
    }

    this.listEntryForm.patchValue({ tagName: '' });
    this.tagSuggestionsOnChange = [];
  }
  searchEntries(searchTerm: any) {
    if (searchTerm !== '' && searchTerm.charAt(0) !== '#') {
      this.foodProviderService.getAllItems(searchTerm).subscribe((response) => {
        if (response) {
          this.itemSuggestions = this.formatData(response.hits.hit);
        }
      })
    }
  }
  onSubmit() {
    this.isLoading = true;
    if (!this.editList) {
      let reqBody = {
        'CuratedListFor': 'Trofimo',
        'Scope': this.listEntryForm?.value?.scope ?? '',
        'Name': this.listEntryForm?.value?.listName ?? '',
        'Ids': this.selectedEntries,
        'IsPrivate': '0',
        'IsTop': '0',
        'likedBy': [],
        'Description': this.listEntryForm?.value?.description ?? '',
        'Tags': this.tags,
        'Type': 'System',
      }
      this.insertAPICall(reqBody);
    } else {
      let reqBody = {
        'CuratedListFor': this.listEntryForm?.value?.curatedListFor ?? '',
        'Name': this.listEntryForm?.value?.listName ?? '',
        'Ids': this.selectedEntries,
        'CreatedDate': this.currentList['CreatedDate'],
        'Description': this.listEntryForm.value.description ? this.listEntryForm.value.description : '',
        'IsPrivate': this.currentList['IsPrivate'] ? this.currentList['IsPrivate'] : '0',
        'IsTop': this.currentList['IsTop'] ? this.currentList['IsTop'] : '0',
        'likedBy': this.currentList['likedBy'],
        'Scope': this.listEntryForm?.value?.scope ?? '',
        'Tags': this.tags,
        'Type': this.currentList['Type'] ? this.currentList['Type'] : '',
        'listNameChanged': this.currentList['Name'] !== this.listEntryForm.value.listName  ? true : false
      }
      this.updateAPICall(reqBody);
    }
  }
  insertAPICall(reqBody: any) {
    this.foodProviderService.addList(reqBody).subscribe(
      (response: any) => {
        //console.log('apiresponse after submit', response);
        if (response === 'success') {
          this.isLoading = false
          Swal.fire({
            text: 'Your changes will be visible in the mobile app within 5 minutes.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          }).then(()=> {
            this.router.navigate(['/'])
          })
        } else if (response.message === 'List exists') {
          this.isLoading = false
          Swal.fire({
            text: 'A list with ' + this.listEntryForm.value.listName + ' already exists',
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          })
        }
      },
      error => {
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      });
  }
  updateAPICall(reqBody: any) {
    this.foodProviderService.updateList(reqBody).subscribe(
      (response: any) => {
        if (response === "success") {
          this.isLoading = false
          Swal.fire({
            text: 'Your changes will be visible in the mobile app within 5 minutes.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          }).then(()=> {
            this.router.navigate(['/listsearch'])
          })
        } else if (response.message === 'List exists') {
          this.isLoading = false
          Swal.fire({
            text: 'A list with ' + this.listEntryForm.value.listName + ' already exists',
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          })
        }
      },
      (error) => {
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      });
  }
  searchLocation(place: any) {
    if (!this.stopLocSuggestions) {
      this.enableButton = false;
      this.foodProviderService.getLocationSuggestionsFromLambda(place).subscribe((response) => {
        //console.log(response.predictions)
        this.locationSuggestions = response.predictions;
      })
    }
    this.stopLocSuggestions = false;
  }
  onClickLocation(location: any) {
    //console.log(location)
    this.listEntryForm.patchValue({
      scope: location
    });
    this.locationSuggestions = [];
    this.stopLocSuggestions = true;
    this.enableButton = true;
    this.locationInputFocus = false
  }
  onClickEntry(record: any) {
    if (this.selectedEntries.filter((obj: any) => obj == record.id).length == 0) {
      this.selectedEntries.push(record.id)
      this.itemSuggestions = [];
    } else {
      alert('duplicate id')
    }
  }
  get tagName() {
    return this.listEntryForm.get('tagName');
  }
  navigateToSearch() {
    this.foodProviderService.setListLocation(this.listEntryForm.value.listName)
    this.router.navigate(['/listsearch']);
  }
  removeEntry(index: any) {
    this.selectedEntries.splice(index, 1);
  }
  get listName() { return this.listEntryForm.get('listName'); }

}
