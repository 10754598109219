import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router';
import { FoodProviderService } from '../../services/food-provider.service';
import Swal from 'sweetalert2'

const JSON5 = require('json5');

@Component({
  selector: 'app-food-menu-form',
  templateUrl: './food-menu-form.component.html',
  styleUrls: ['./food-menu-form.component.css']
})
export class FoodMenuFormComponent implements OnInit {
  hours: any = [{ 'open': [] }];
  recommendedDishes: any[] = [];
  arrowIcon: boolean = true;
  isLoading: boolean = false;
  currentFoodProvider: any;
  isEditForm: boolean = false;
  flowType: any = '';
  focusVar: any = '';

  menuForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    hoursFromWeekDay: ['', Validators.required],
    hoursToWeekDay: ['', Validators.required],
    hoursFromTime: ['', Validators.required],
    hoursToTime: ['', Validators.required],
    submenus: this.fb.array([]),
  });

  constructor(private foodProviderService: FoodProviderService,
    private router: Router,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute) {
  }

  newSubmenu(): FormGroup {
    return this.fb.group({
      name: '',
      list: '',
    })
  }

  toggleIcon() {
    this.arrowIcon = !this.arrowIcon;
  }


  addSubmenu() {
    this.submenus.push(this.newSubmenu());
  }

  removeSubmenu(i: number) {
    this.submenus.removeAt(i);
  }

  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    this.currentFoodProvider = this.foodProviderService.getCurrentRestaurant();
    if (this.currentFoodProvider === undefined) {
      this.router.navigate(['']);
    }
    this.foodProviderService.getDishesAndAddons().subscribe((response) => {
      this.recommendedDishes = response.hits.hit;
    })
    if (this.actRoute.snapshot.params.flowType === 'edit') {
      this.isEditForm = true;
      //console.log(this.foodProviderService.getMenu())
      let menu = this.foodProviderService.getMenu()
      let submenus = JSON5.parse(menu.dishes);
      let submenuArr: any = [];
      for (const property in submenus) {
        submenuArr.push({ 'name': property, list: submenus[property] });
        this.addSubmenu();
      }
      //console.log(submenuArr);
      menu['submenus'] = submenuArr;
      if (menu.open_hours) {
        this.hours = JSON.parse(menu.open_hours);
      }
      this.menuForm.patchValue(menu);
    } else {
      this.addSubmenu();
    }
  }

  addHours() {
    if (this.hoursFromWeekDay?.value === this.hoursToWeekDay?.value) {
      this.hours[0].open.push({ start: this.hoursFromTime?.value.replace(':', ''), end: this.hoursToTime?.value.replace(':', ''), day: parseInt(this.hoursFromWeekDay?.value) })
    } else {
      for (let i = parseInt(this.hoursFromWeekDay?.value); i <= parseInt(this.hoursToWeekDay?.value); i++) {
        this.hours[0].open.push({ start: this.hoursFromTime?.value.replace(':', ''), end: this.hoursToTime?.value.replace(':', ''), day: i })
      }
    }
    this.menuForm.patchValue({ hoursFromWeekDay: '', hoursToWeekDay: '', hoursFromTime: '', hoursToTime: '' })
  }

  removeHour(item: any, index: number) {
    this.hours[0].open.splice(index, 1)
  }

  getRecordType(type: any) {
    return type === 'Addon' ? ' -- Addon' : null;
  }
  saveMenu() {
    this.isLoading = true;
    let dishes: any = {};
    for (let submenu of this.submenus.value) {
      if (submenu.name) {
        dishes[submenu.name] = submenu.list
      }
    }
    let reqBody = {
      "name": this.name?.value,
      "description": this.description?.value,
      "dishes": JSON.stringify(dishes),
      "record_type": 'menu',
      "source": 'Trofimo',
      "food_provider":this.currentFoodProvider.fields.name,
      "food_provider_and_location": this.currentFoodProvider.fields.name + '_' + this.currentFoodProvider.fields.location_address_1,
      "open_hours": JSON.stringify(this.hours)
  }
    this.foodProviderService.addMenu(reqBody).subscribe((resp) => {
      //console.log(resp);
      if (resp === 'success') {
        this.menuForm.reset();
        this.isLoading = false;
        Swal.fire({
          text: this.isEditForm ? 'Menu updated successfully' : 'Menu inserted successfully',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/menulist']);
          }
        })
      } else {
        this.isLoading = false;
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      }
    })
  }


  get submenus(): FormArray {
    return this.menuForm.get('submenus') as FormArray;
  }

  get name() { return this.menuForm.get('name'); }

  get description() { return this.menuForm.get('description'); }

  get hoursFromWeekDay() { return this.menuForm.get('hoursFromWeekDay'); }
  get hoursToWeekDay() { return this.menuForm.get('hoursToWeekDay'); }
  get hoursFromTime() { return this.menuForm.get('hoursFromTime'); }
  get hoursToTime() { return this.menuForm.get('hoursToTime'); }

  get isHoursFormValid() {
    return (this.menuForm.get('hoursFromWeekDay')?.valid
      && this.menuForm.get('hoursToWeekDay')?.valid
      && this.menuForm.get('hoursFromTime')?.valid
      && this.menuForm.get('hoursToTime')?.valid)
  }
}
