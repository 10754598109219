import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.css']
})
export class ListSearchComponent implements OnInit {
  listSearchForm: any;
  curatedList: any = [];
  isLoading: boolean = false;
  noResultsFound: boolean = false;
  currentListItem: any;

  constructor(private foodProviderService: FoodProviderService, private router: Router) {
    this.listSearchForm = new FormGroup({
      listName: new FormControl('', [Validators.required]),
      searchScope: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }
  navigateToList(list: any) {
    this.foodProviderService.setCurrentList(list)
    this.router.navigate(['/listentry', { flowType: 'edit' }]);
  }
  onSearch() {
    this.isLoading = true
    this.noResultsFound = false;
    this.curatedList = []
    let reqBody = {
      searchTerm: this.listSearchForm?.value?.listName,
      searchScope: this.listSearchForm?.value?.searchScope ?? undefined
    }
    this.foodProviderService.searchCuratedLists(reqBody).subscribe(
      (response: any) => {
        console.log('apiresponse after submit', response?.length);
        if (response !== undefined && response?.length !== 0) {
          this.isLoading = false
          this.curatedList = response
        } else {
          this.noResultsFound = true;
          this.isLoading = false
        }
      });
  }
  setCurrentList(list: any){
    this.currentListItem = list;
  }
  getListName() {
    let listName = this.currentListItem !== undefined && this.currentListItem !== '' && this.currentListItem.Name
    return listName;
  }
  removeItem() {
    let reqBody = {
      'CuratedListFor': this.currentListItem.CuratedListFor,
      'CreatedDate': this.currentListItem.CreatedDate
    } 
    this.foodProviderService.deleteList(reqBody).subscribe((response: any) => {
      if (response === 200) {
        this.curatedList.splice(this.curatedList.findIndex(item => item.Name === this.currentListItem.Name), 1);
      }
    })
  }
  get listName() { return this.listSearchForm.get('listName'); }
}
