import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FoodProviderService {
  cloudSearchRestaurants: any = [];
  name: string = '';
  city: string = '';
  currentRestaurant: any;
  menu: any;
  dish: any;
  event: any;
  list: any;
  configdata: any;
  listLocation: any;

  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': environment.xApiKey,
    }),
  };

  httpOptionsYelp = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + environment.yelpAPIkey,
    }),
  };
  googleHttpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  getCSRestaurants() {
    return this.cloudSearchRestaurants;
  }
  setCSRestaurants(restaurants: any) {
    this.cloudSearchRestaurants = restaurants;
  }

  setNameAndCity(name: string, city: string) {
    this.name = name;
    this.city = city;
    localStorage.setItem('name', name);
    localStorage.setItem('city', city);
  }

  getCurrentRestaurant() {
    let localRestaurant = localStorage.getItem('currentRestaurant');
    //console.log(localRestaurant);
    return localRestaurant
      ? JSON.parse(localRestaurant)
      : this.currentRestaurant;
  }
  setCurrentRestaurant(currentRestaurant: any) {
    //console.log(currentRestaurant);
    this.currentRestaurant = currentRestaurant;
    localStorage.setItem(
      'currentRestaurant',
      JSON.stringify(currentRestaurant)
    );
  }
  getName() {
    let localName = localStorage.getItem('name');
    return localName ? localName : this.name;
  }

  getCity() {
    let localCity = localStorage.getItem('city');
    return localCity ? localCity : this.city;
  }

  setMenu(menu: any) {
    localStorage.setItem('menu', JSON.stringify(menu));
    this.menu = menu;
  }

  getMenu() {
    let localMenu = localStorage.getItem('menu');
    return localMenu ? JSON.parse(localMenu) : this.menu;
  }
  setCurrentDish(dish: any) {
    //console.log('settingCurrentDish', dish);
    this.dish = dish;
    localStorage.setItem('dish', JSON.stringify(dish));
  }
  getCurrentDish() {
    let dish = localStorage.getItem('dish');
    //console.log('gettingCurrentDish', dish);
    return dish ? JSON.parse(dish) : this.dish;
  }
  setCurrentEvent(event: any) {
    //console.log('settingCurrentEvent', event);
    this.event = event;
    localStorage.setItem('event', JSON.stringify(event));
  }
  getCurrentEvent() {
    let event = localStorage.getItem('event');
    //console.log('gettingCurrentEvent', event);
    return event ? JSON.parse(event) : this.event;
  }
  setCurrentList(list: any) {
    //console.log('settingCurrentlist', list);
    this.list = list;
    localStorage.setItem('list', JSON.stringify(list));
  }
  getCurrentList() {
    let list = localStorage.getItem('list');
    //console.log('gettingCurrentList', list);
    return list ? JSON.parse(list) : this.list;
  }
  setConfigData(configdata: any) {
    //console.log('settingCurrentDish', dish);
    this.configdata = configdata;
    localStorage.setItem('configdata', JSON.stringify(configdata));
  }
  getConfigData() {
    let configdata = localStorage.getItem('configdata');
    //console.log('gettingCurrentDish', dish);
    return configdata ? JSON.parse(configdata) : this.configdata;
  }
  getRestaurants(): Observable<any> {
    let endpoint = environment.foodProviderCloudSearch.replace(
      'name_placeholder',
      this.getName()
    );
    endpoint = endpoint.replace('city_placeholder', this.getCity());
    return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getRestaurants', []))
    );
  }
  getUserDetails() {
    let userDetails = localStorage.getItem('currentUser');
    return userDetails ? JSON.parse(userDetails) : {};
  }
  setListLocation(listLocation: string) {
    this.listLocation = listLocation;
    localStorage.setItem('listLocation', listLocation);
  }
  getListLocation() {
    let localName = localStorage.getItem('listLocation');
    return localName ? localName : this.listLocation;
  }
  getDishesAndAddons(): Observable<any> {
    let restDetails = this.getCurrentRestaurant();
    let endpoint = environment.dishesAndAddons.replace(
      'name_placeholder',
      restDetails.fields.name + '_' + restDetails.fields.location_address_1
    );
    //console.log('getDishesAndAddons', endpoint);
    return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getDishesAndAddons', []))
    );
  }

  getDishorAddons(type: any): Observable<any> {
    let restDetails = this.getCurrentRestaurant();
    let endpoint = environment.dishOrAddons.replace(
      'name_placeholder',
      restDetails.fields.name + '_' + restDetails.fields.location_address_1
    );
    let newEndPoint = endpoint.replace('rtype', type);
    return this.http.get<any>(encodeURI(newEndPoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getAddons', []))
    );
  }
getItemsWithRT(endpoint: string): Observable<any> {
  // let endpoint = environment.itemsWithRT;
  // let newEndPoint = endpoint.replace('rtype', type);
  return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
    // tap(ev=> //console.log(ev)),
    catchError(this.handleError<any>('getItemsWithRT', []))
  );
}
getAllItems(searchInput: string): Observable<any> {
  let endpoint = environment.allRecords;
  let newEndPoint =  endpoint.replace('searchInput', searchInput)
  return this.http.get<any>(newEndPoint, this.httpOptions).pipe(
    // tap(ev=> //console.log(ev)),
    catchError(this.handleError<any>('getAllItems', []))
  );
}

  addRestaurant(reqBody: any) {
    return this.http
      .post<any>(environment.addRestaurant, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addRestaurant', []))
      );
  }

  addDish(reqBody: any) {
    return this.http
      .post<any>(environment.addDish, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addDish', []))
      );
  }

  addTagsInItems(reqBody: any) {
    return this.http
      .post<any>(environment.addTagsInItems, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addTagsInItems', []))
      );
  }

  addMenu(reqBody: any) {
    return this.http
      .post<any>(environment.addMenu, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addMenu', []))
      );
  }
  addTagsInConfig(reqBody: any) {
    //console.log('addTagsInConfig()', reqBody)
    return this.http
      .post<any>(environment.addTagsInConfig, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addTagsInConfig', []))
      );
  }
  deleteTagsInConfig(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.xApiKey,
      }),
      body: reqBody,
    };
    let endpoint = environment.deleteTagsInConfig;
    return this.http.delete<any>(endpoint, options).pipe(
      // tap(ev=> //console.log(ev)), body, this.httpOptions
      catchError(this.handleError())
    );
  }
  getMenus(): Observable<any> {
    let restDetails = this.getCurrentRestaurant();
    let endpoint = environment.getMenus.replace(
      'name_placeholder',
      restDetails.fields.name + '_' + restDetails.fields.location_address_1
    );
    return this.http.get<any>(endpoint, this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getDishes', []))
    );
  }
  deleteDishOrMenuOrEvent(reqBody: any) {
    this.httpOptions['body'] = reqBody
    return this.http
      .delete<any>(
        environment.deleteDishOrMenuOrEvent,
        this.httpOptions
      )
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('deleteDishOrMenuOrEvent', []))
      );
  }
  // deleteTag(reqBody: any) {
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'x-api-key': environment.xApiKey,
  //     }),
  //     body: reqBody,
  //   };
  //   let endpoint = environment.deletetag;
  //   return this.http.delete<any>(endpoint, options).pipe(
  //     // tap(ev=> //console.log(ev)), body, this.httpOptions
  //     catchError(this.handleError())
  //   );
  // }
  getMultipleConfig(configData: any) {
    const body = { configNames: configData };
    let endpoint = environment.getMultipleConfig;
    return this.http.post<any>(endpoint, body, this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getMultipleConfig', []))
    );
  }

  addEvent(reqBody: any) {
    return this.http
      .post<any>(environment.insertEvent, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addEvent', []))
      );
  }
  addList(reqBody: any) {
    return this.http
      .post<any>(environment.insertList, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('addList', []))
      );
  }
  updateList(reqBody: any) {
    return this.http
      .put<any>(environment.updateList, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('updateList', []))
      );
  }
  getItemById(itemId: any) {
    let endpoint = environment.getItembyId.replace('item_id', itemId);
    // console.log('getItemById', endpoint);
    return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getItemById', []))
    );
  }
  getTag(username: any, tagname: any) {
    let endpoint =
      environment.getTag + '?UserName=' + username + '&CreatedDate=' + tagname;
    return this.http
      .get<any>(endpoint, this.httpOptions)
      .pipe(catchError(this.handleError<any>('get_tag', [])));
  }
  getPendingTags() {
    let endpoint =
      environment.getPendingTags;
    return this.http
      .get<any>(endpoint, this.httpOptions)
      .pipe(catchError(this.handleError<any>('getPendingTags', [])));
  }
  approveTag(reqBody: any) {
    return this.http
      .post<any>(environment.approveTag, reqBody, this.httpOptions)
      .pipe(
        // tap(ev=> //console.log(ev)),
        catchError(this.handleError<any>('approveTag', []))
      );
  }
  deleteTag(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.xApiKey,
      }),
      body: reqBody,
    };
    let endpoint = environment.deleteTag;
    return this.http.delete<any>(endpoint, options).pipe(
      // tap(ev=> //console.log(ev)), body, this.httpOptions
      catchError(this.handleError())
    );
  }
  getEventSuggestions(searchTerm: any) {
    let endpoint = environment.eventSuggestions.replace(
      'searchterm',
      searchTerm
    );
    // console.log('getEventSuggestions', endpoint);
    return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getEventSuggestions', []))
    );
  }
  getAllSuggestions(searchTerm: any) {
    let endpoint = environment.allSuggestions.replace('searchterm', searchTerm);
    // console.log('getAllSuggestions', endpoint);
    return this.http.get<any>(encodeURI(endpoint), this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getAllSuggestions', []))
    );
  }
  validateHashtag(hashtag: any) {
    let ht = hashtag.replace('#', '%23');
    let endpoint = environment.validateHashtag.replace('hashtagTerm', ht);
    // console.log('validateHashtag', endpoint);
    return this.http.get<any>(endpoint, this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('validateHashtag', []))
    );
  }
  searchCuratedLists(reqBody: any) {
    let endpoint = environment.listSearch;
    return this.http.post<any>(endpoint, reqBody, this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('searchCuratedLists', []))
    );
  }
  deleteList(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.xApiKey,
      }),
      body: reqBody,
    };
    let endpoint = environment.deleteList;
    return this.http.delete<any>(endpoint, options).pipe(
      // tap(ev=> //console.log(ev)), body, this.httpOptions
      catchError(this.handleError())
    );
  }
  getYelpDataFromLambda() {
    const body = { name: this.getName(), city: this.getCity() };
    let endpoint = environment.yelpProxyFromLambda;
    return this.http.post<any>(endpoint, body, this.httpOptions).pipe(
      // tap(ev=> //console.log(ev)),
      catchError(this.handleError<any>('getYelpDataFromLambda', []))
    );
  }
  getSpecificYelpRestaurantFromLambda(id: string) {
    const body = { id: id };
    let endpoint = environment.yelpRestaurantByIdFromLambda;
    return this.http.post<any>(endpoint, body, this.httpOptions).pipe(
      catchError(this.handleError<any>('yelpRestaurantByIdFromLambda', []))
    );
  }
  getLocationSuggestionsFromLambda(place: any) {
    const body = { place: place };
    let endpoint = environment.googlePlaceSuggestionsFromLambda;
    return this.http.post<any>(endpoint, body, this.httpOptions).pipe(
      catchError(this.handleError<any>('googlePlaceSuggetsionsFromLambda', []))
    );
  }
  getGoogleImagesFromLambda(itemName: any) {
    const body = { itemName: itemName };
    let endpoint = environment.googleImagesFromLambda;
    return this.http.post<any>(endpoint, body, this.httpOptions).pipe(
      catchError(this.handleError<any>('getGoogleImagesFromLambda', []))
    );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
