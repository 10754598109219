import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(private localStorage: LocalStorageService, private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot
   ) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.checkUserLogin(route)) {
        return true;
      } else {
        this.router.navigate(['/auth']);
        return false;
      }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot
    ) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    // console.log("currentUser", currentUser)
    let allowUser = false;
    if (currentUser && currentUser.signInUserSession) {
      let userRole = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
      // console.log(currentUser.signInUserSession.accessToken.payload['cognito:groups'])
      // console.log(route.data.roles)
      for (let index = 0; index < userRole.length; index++) {
        if (route.data.roles && route.data.roles.indexOf(userRole[index]) === -1) {
          // console.log("in if")
          allowUser = false;
        } else{
          // console.log("in else")
          allowUser = true
          break
        }
      }
    }
    return allowUser;
  }

  checkUserEligible(){

  }
  getRoutePermissions(route: ActivatedRouteSnapshot): string[] {
    if (route.data && route.data.userRoles) {
      return route.data.userRoles as string[];
    }
    return [];
  }
}
