<div class="container">
  <p class="fs-5 mt-5">Add an item</p>
  <div class="row">
    <div>
      <button type="button" class="btn btn-primary mt-2" (click)="navigateToRest()">Restaurant</button>
    </div>
    <div>
      <button type="link" class="btn btn-primary mt-2" (click)="navigateToList()">Curated List</button>
    </div>
    <div>
      <button type="button" class="btn btn-primary mt-2" (click)="navigateToEvent()"
        routerLink="/evententry">Event</button>
    </div>
    <div>
      <button type="button" class="btn btn-primary mt-2" routerLink="/reviewtag">Create / View Tags</button>
    </div>
    <div>
      <button type="button" class="btn btn-primary mt-2" routerLink="/addtags">Add Tags to items</button>
    </div>
  </div>
</div>