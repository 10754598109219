import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

const JSON5 = require('json5');

@Component({
  selector: 'app-food-menu',
  templateUrl: './food-menu.component.html',
  styleUrls: ['./food-menu.component.css']
})
export class FoodMenuComponent implements OnInit {
  menus: any = [];
  isLoading: boolean = true;
  menuName: string = '';
  menuIndex: number = -1;
currentRestaurant : any
  constructor(private foodProviderService: FoodProviderService,
    private router: Router) { }

  ngOnInit(): void {
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    // console.log('this.currentRestaurant', this.currentRestaurant);
    if(this.currentRestaurant === undefined){
      this.router.navigate(['']);
    }
    this.foodProviderService.getMenus().subscribe(resp=>{
      //console.log(resp);
      this.menus = resp;
      this.isLoading = false;
    })
  }

  edit(menu: any){
    this.foodProviderService.setMenu(menu);
    this.router.navigate(['menuform', {flowType: 'edit'}]);
  }

  getKey(obj: any){
    return Object.keys(obj)[0];
  }
  getValue(obj: any){
    return obj[Object.keys(obj)[0]];
  }

  setMenuNameAndIndex(name: string, index: number){
    this.menuName = name;
    this.menuIndex = index;
  }
  
  deleteMenu(){
    let reqBody = {
      "food_provider_and_location": this.currentRestaurant.fields.name + '_' + this.currentRestaurant.fields.location_address_1,
      "name": this.menuName,
      "type": "Menu"
    }
    this.foodProviderService.deleteDishOrMenuOrEvent(reqBody).subscribe((response) => {
      if (response === 'success'){
        this.menus.splice(this.menuIndex, 1);
        Swal.fire({
          text: 'Menu deleted successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      }
    })
  }

  menuSections(data: string){
    let obj: any  = JSON5.parse(data);
    let keys: Array<string> = Object.keys(obj);
    let menuSections: Array<Object> = [];
    keys.forEach((key: string) => {
      menuSections.push({[key]: obj[key]});
      
    });
    return menuSections;
  }

}
