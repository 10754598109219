import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dish-list',
  templateUrl: './dish-list.component.html',
  styleUrls: ['./dish-list.component.css']
})
export class DishListComponent implements OnInit {
  recommendedDishes: any[] = [];
  isLoading: boolean = true;
  currentDishItem: any;
  config: any;
  page: any = 1;
  pre = '';
  nxt = '';
  resp = true;
  totalRecords: any;
  link = true;
  flowType: any = '';
  searchText: any;

  constructor(private foodProviderService: FoodProviderService, private router: Router) {
   }

  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    let currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    if (currentRestaurant === undefined) {
      this.router.navigate(['/'])
    }
    this.foodProviderService.getDishesAndAddons().subscribe((response) => {
      this.recommendedDishes = response.hits.hit;
      this.isLoading = false;
      //console.log('this.getDishes', this.recommendedDishes)
    })
  }
  setCurrentDish(item: any) {
    this.currentDishItem = item;
  }
  removeDish() {
    let reqBody = {
      "food_provider_and_location": this.currentDishItem.fields.food_provider_and_location,
      "name": this.currentDishItem.fields.name,
      "type": "Dish"
  }
    this.foodProviderService.deleteDishOrMenuOrEvent(reqBody).subscribe((response: any) => {
      if (response === 'success') {
        this.recommendedDishes.splice(this.recommendedDishes.findIndex(item => item.id === this.currentDishItem.id), 1);
        this.currentDishItem = '';
      }
    })
  }
  pageChanged(event: any) {
    this.config.currentPage = event;
  }
  newDish() {
    this.foodProviderService.setCurrentDish({})
    this.router.navigate(['/dishentry']);
  }
  editDish(item: any) {
    this.foodProviderService.setCurrentDish(item)
    this.router.navigate(['/dishentry', { flowType: 'edit' }]);
  }
  getDescription(description) {
    if (!description || description.length === 0) {
      return 'No Description';
    }
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  }

  getCategory(obj) {
    if (obj && obj.length > 0 && obj !== "[]") {
      return obj[0];
    }
    return "No Classification";
  }

  getPrice(price) {
    if (price && price !== '') {
      return parseFloat(price).toFixed(2);
    }
    return 'No Price';
  }

  getName() {
    return this.currentDishItem?.fields?.name || '';
  }

  getUrlFromMedia(obj: any) {
    const media = obj && obj.length > 0 ? JSON.parse(obj) : [];
    return media[0]?.url || 'assets/images/DishSquareIcon.png';
  }
}
