import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-food-provider-start',
  templateUrl: './food-provider-start.component.html',
  styleUrls: ['./food-provider-start.component.css']
})
export class FoodProviderStartComponent implements OnInit {
  foodProviderQueryForm: any;
  isLoading: boolean = false;

  constructor(private foodProviderService: FoodProviderService,
    private router: Router) { 
    
  }

  ngOnInit(): void {
    this.foodProviderQueryForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    this.isLoading = true;
    const {name, city } = this.foodProviderQueryForm.value;
    this.foodProviderService.setNameAndCity(name, city);
    this.router.navigate(['/foodproviderselect']);
  }

  setNameAndCity(){
    
  }

  get name() { return this.foodProviderQueryForm.get('name'); }
  get city() { return this.foodProviderQueryForm.get('city'); }

}
