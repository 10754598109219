<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderhours">
                &nbsp; FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderattributes">
                &nbsp; FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor" routerLink="/dishlist">
                &nbsp; Dish
            </span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line">

    <div class="container">
        <div class="panel-heading">
            <div>
                <span style="display:inline; color: black;margin-left: 10px; cursor: pointer;" routerLink="/menulist">
                    Menu
                </span>
                <span>
                    <button
                        (click)='newDish()'
                        type="button"
                        [ngClass]="{'add-menu-btn': true}"
                        style="float: right;margin-right: 18px;background-color: #FF8A3B;"
                    >
                        ADD FOOD ITEM
                    </button>
                </span>
            </div>
        </div>
        <div class="panel-body mt-5">
            <input [(ngModel)]="searchText" placeholder="Search for an item">
            <table class="table" aria-describedby="dish_list">
                <thead>
                    <tr>
                        <th scope="col" style="font-weight: normal;">
                            S.No
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Image
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Item name
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Description
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Price ($)
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Classification
                        </th>
                        <th scope="col" style="font-weight: normal;">
                            Type
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of recommendedDishes| search:'name,description':searchText | paginate
                    : {
                        id: 'listing_pagination',
                        itemsPerPage: 10,
                        currentPage: page,
                        totalItems: totalRecords
                      };index as i">
                        <td class="align-middle">{{10 *(page-1)+i+1}}</td>
                        <td class="align-middle">
                            <img onerror="this.src='assets/images/DishSquareIcon.png';" style="width: 76px; height: 74px;" alt="Image" src={{getUrlFromMedia(item.fields.media)}}>
                        </td>
                        <td class="align-middle">{{item.fields.name}}</td>
                        <td class="align-middle" style=" width: 205px;">
                            {{getDescription(item.fields.description)}}
                        </td>
                        <td class="align-middle">{{getPrice(item.fields.price)}}</td>
                        <td class="align-middle">
                            {{getCategory(item.fields.dish_classification)}}
                        </td>
                        <td class="align-middle">
                            {{item.fields.record_type}}
                        </td>
                        <td class="align-middle">
                            <button type="button" (click)='editDish(item)' class="edit-btn">
                                EDIT
                            </button>
                            <button (click)='setCurrentDish(item)' type="button" data-bs-toggle="modal" data-bs-target="#deleteModal" class="delete-btn">
                                <img
                                    alt="Image"
                                    style="width: 14px; height: 14px;"
                                    src="assets/images/delete_white 3.png"
                                >
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!isLoading && recommendedDishes.length === 0" class="alert alert-primary mt-5" role="alert">
                No results found.
            </div>
            <div style="padding-bottom: 20px;">
                <pagination-controls id="listing_pagination" [directionLinks]="link" (pageChange)="page = $event" [responsive]="resp" [previousLabel]="pre" [nextLabel]="nxt">
                </pagination-controls>
            </div>
        </div>
        <span *ngIf="isLoading" class="spinner-border text-warning mx-auto" role="status">
        </span>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                    Are you sure want to delete
                    <br>
                    <strong>{{getName()}}</strong> ?
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-footer">
                <button type="button" class="edit-btn" style="width: 90px;" data-bs-dismiss="modal">
                    CANCEL
                </button>
                <button (click)='removeDish()' type="button" class="edit-btn" style="width: 90px; color: #FFFFFF; background-color: #FF8A3B;" data-bs-dismiss="modal">
                    DELETE
                </button>
            </div>
        </div>
    </div>
</div>