import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';;

@Component({
  selector: 'app-food-provider-info',
  templateUrl: './food-provider-info.component.html',
  styleUrls: ['./food-provider-info.component.css']
})
export class FoodProviderInfoComponent implements OnInit {
  currentRestaurant: any;
  foodProviderInfoForm: any;
  flowType: any = '';

  constructor(private foodProviderService: FoodProviderService,
    private router: Router) { 
    this.foodProviderInfoForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      subtitle: new FormControl(''),
      description: new FormControl(''),
      website: new FormControl(''),
      phone: new FormControl('', [Validators.pattern('[0-9+]*')]),
      special_hours: new FormControl(''),
      open_hours: new FormControl(''),
      place_id: new FormControl(''),
      display_phone: new FormControl(''),
      provider_id: new FormControl(''),
      provider_url: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    this.currentRestaurant =  this.foodProviderService.getCurrentRestaurant();
    if(this.currentRestaurant.fields !== undefined){
      this.foodProviderInfoForm.patchValue(this.currentRestaurant.fields);
    }
  }
  get phonenumber() {
    return this.foodProviderInfoForm.get('phone');
  }
  get display_phone() {
    return this.foodProviderInfoForm.get('display_phone');
  }
  onSubmit(){
    let currentRestaurantString = localStorage.getItem('currentRestaurant');
    let currentRestaurant = currentRestaurantString ?JSON.parse(currentRestaurantString): {};
    if(currentRestaurant.fields === undefined){
      currentRestaurant['fields'] = {}
    }
    currentRestaurant['fields']['name'] = this.foodProviderInfoForm.value.name;
    currentRestaurant['fields']['subtitle'] = this.foodProviderInfoForm.value.subtitle;
    currentRestaurant['fields']['description'] = this.foodProviderInfoForm.value.description;
    currentRestaurant['fields']['website'] = this.foodProviderInfoForm.value.website;
    currentRestaurant['fields']['phone'] = this.foodProviderInfoForm.value.phone;
    currentRestaurant['fields']['display_phone'] = this.foodProviderInfoForm.value.display_phone;
    currentRestaurant['fields']['special_hours'] = this.foodProviderInfoForm.value.special_hours;
    currentRestaurant['fields']['open_hours'] = this.foodProviderInfoForm.value.open_hours;
    currentRestaurant['fields']['place_id'] = this.foodProviderInfoForm.value.place_id;
    currentRestaurant['fields']['provider_id'] = this.foodProviderInfoForm.value.provider_id;
    currentRestaurant['fields']['provider_url'] = this.foodProviderInfoForm.value.provider_url;
    this.foodProviderService.setCurrentRestaurant(currentRestaurant)
    this.router.navigate(['/foodproviderlocation']);
  }
}
