import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FoodProviderService } from '../../services/food-provider.service';

@Component({
  selector: 'app-food-provider-hours',
  templateUrl: './food-provider-hours.component.html',
  styleUrls: ['./food-provider-hours.component.css']
})
export class FoodProviderHoursComponent implements OnInit {

  foodProviderHours: any;
  currentRestaurant: any;
  hours: any = [{'open': []}];
  specialHours: any = [{'open': []}];
  flowType: any = '';


  constructor(private foodProviderService: FoodProviderService,
    private router: Router) {
    this.foodProviderHours = new FormGroup({
      location_address_1: new FormControl(''),
      location_address_2: new FormControl(''),
      location_address_3: new FormControl(''),

      closed_out: new FormControl(false),

      hoursFromWeekDay: new FormControl('',[Validators.required]),
      hoursToWeekDay: new FormControl('',[Validators.required]),
      hoursFromTime: new FormControl('',[Validators.required]),
      hoursToTime: new FormControl('',[Validators.required]),

      specialHoursFromWeekDay: new FormControl('',[Validators.required]),
      specialHoursToWeekDay: new FormControl('',[Validators.required]),
      specialHoursFromTime: new FormControl('',[Validators.required]),
      specialHoursToTime: new FormControl('',[Validators.required]),

    });
  }

  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    //console.log(this.currentRestaurant.fields.source)

    if(this.currentRestaurant.fields.open_hours){
      if(this.currentRestaurant.fields.open_hours.includes('periods')){
        let openHours = this.currentRestaurant.fields.open_hours;
        openHours = openHours.replace(/True/g, 'true');
        openHours = openHours.replace(/False/g, 'false');
        openHours = openHours.replace(/'/g, '"');
        openHours = JSON.parse(openHours);

        openHours.periods.forEach((element: any) => {
          let day = element.open.day == 0 ? 6: element.open.day-1;
          this.hours[0].open.push({start: element.open.time, end: element.close.time, day: day})
        });

      }else{
        this.hours = JSON.parse(this.currentRestaurant.fields.open_hours);
      }
    }
    if(this.currentRestaurant.fields.closed_out){
      this.foodProviderHours.patchValue({closed_out: this.currentRestaurant.fields.closed_out === '0'? false: true})
    }
  }

  removeHour(item: any, index: number){
    this.hours[0].open.splice(index, 1)
  }

  onClickBtn(){
    const { closed_out} = this.foodProviderHours.value;

    this.currentRestaurant['fields']['open_hours'] = JSON.stringify(this.hours);
    this.currentRestaurant['fields']['closed_out'] = closed_out? '1':'0';

    this.foodProviderService.setCurrentRestaurant(this.currentRestaurant)
    this.router.navigate(['/foodproviderattributes']);

  }
  
  addHours(){
    if(this.hoursFromWeekDay.value === this.hoursToWeekDay.value){
      this.hours[0].open.push({start: this.hoursFromTime.value.replace(':',''), end: this.hoursToTime.value.replace(':',''), day: parseInt(this.hoursFromWeekDay.value)})
    }else{
      for(let i= parseInt(this.hoursFromWeekDay.value); i<= parseInt(this.hoursToWeekDay.value); i++){
        this.hours[0].open.push({start: this.hoursFromTime.value.replace(':',''), end: this.hoursToTime.value.replace(':',''), day: i})
      }
    }
    this.foodProviderHours.patchValue({hoursFromWeekDay:'', hoursToWeekDay:'', hoursFromTime:'', hoursToTime:''})
  }

  get hoursFromWeekDay() { return this.foodProviderHours.get('hoursFromWeekDay'); }
  get hoursToWeekDay() { return this.foodProviderHours.get('hoursToWeekDay'); }
  get hoursFromTime() { return this.foodProviderHours.get('hoursFromTime'); }
  get hoursToTime() { return this.foodProviderHours.get('hoursToTime'); }

  get isHoursFormValid(){
    return (this.foodProviderHours.get('hoursFromWeekDay').valid 
    && this.foodProviderHours.get('hoursToWeekDay').valid
    && this.foodProviderHours.get('hoursFromTime').valid
    && this.foodProviderHours.get('hoursToTime').valid)
  }
}
