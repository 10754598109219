<button type="button" class="view-all-btn" routerLink="/reviewtag">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
        <path fill-rule="evenodd"
            d="M9.78 12.78a.75.75 0 01-1.06 0L4.47 8.53a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 1.06L6.06 8l3.72 3.72a.75.75 0 010 1.06z">
        </path>
    </svg>
    View all
</button>
<div class="container mt-5">

    <div class="d-flex flex-column" *ngIf="
      restaurantDetails != [] &&
      restaurantDetails != undefined &&
      tagDetails != [] &&
      tagDetails != undefined
    ">
        <h4>Review Tag</h4>
        <!-- <p>
            New tag suggested for a {{ tagDetails.RecordType }} from {{ tagDetails.Source }}
        </p> -->
        <p>
            Tag name : <b>{{ tagDetails.TagName }}</b>
        </p>
        <p>
            Exsisting Tags in {{ restaurantDetails.name }}:
            <b>{{getExistingTags()}}</b>
        </p>
        <div class="d-flex flex-row">
            <div>
                <div *ngIf="isLoadingApprove; then thenBlockBtn1; else elseBlockBtn1"></div>
                <ng-template #thenBlockBtn1>
                    <button class="save-btn-disabled mb-5" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        approving...
                    </button>
                </ng-template>
                <ng-template #elseBlockBtn1>
                    <button class="save-btn" type="button" (click)="onSubmit('APPROVE')">
                        APPROVE
                    </button>
                </ng-template>
            </div>
            <div class="mx-2">
                <div *ngIf="isLoadingReject; then thenBlockBtn2; else elseBlockBtn2"></div>
                <ng-template #thenBlockBtn2>
                    <button class="save-btn-disabled mb-5" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        deleting...
                    </button>
                </ng-template>
                <ng-template #elseBlockBtn2>
                    <button [ngClass]="{ 'reject-btn': true }" type="button" (click)="onSubmit('REJECT')">
                        REJECT
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <span class="spinner-border sspinner-border-xl" role="status" aria-hidden="true"></span>
    </div>
</div>