import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Storage } from 'aws-amplify';
import debounce from 'lodash.debounce';

const moment = require('moment');
const JSON5 = require('json5');

@Component({
  selector: 'app-event-entry',
  templateUrl: './event-entry.component.html',
  styleUrls: ['./event-entry.component.css']
})
export class EventEntryComponent implements OnInit {

  mediaArr: any = [];
  selectedFiles: any[] = [];
  participantSuggestions: any = [];
  selectedParticipants: any = [];
  selectedParticipantsToDisplay: any = [];
  previews: string[] = [];
  currentEvent: any;
  currentUserDetails: any;
  currentRestaurant: any;
  editMode: boolean = false;
  hashtagChanged: boolean = false;
  isLoading: boolean = false;
  imagesUploading: boolean = false;
  progressFlowType: any = '';
  eventForm: any;
  budgetList: Array<string> = ['$', '$$', '$$$', '$$$$'];
  contentList: Array<string> = ['Image', 'Video'];
  categories: Array<any> = [];
  eventStartDate: any;
  eventEndDate: any;
  duplicateHashtag: any = '';
  disableButton: any = '';

  constructor(private _Activatedroute: ActivatedRoute, private formBuilder: FormBuilder, private foodProviderService: FoodProviderService, private router: Router) {
    this.searchParticipants = debounce(this.searchParticipants, 500);
    let configData = this.foodProviderService.getConfigData();
    this.categories = JSON.parse(configData).Cuisine;

    this.eventForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      subtitle: new FormControl(''),
      description: new FormControl(''),
      website: new FormControl(''),
      display_phone: new FormControl(''),
      location_city: new FormControl(''),
      location_country: new FormControl(''),
      location_zip: new FormControl(''),
      location_state: new FormControl(''),
      coordinates: new FormControl(''),
      budget: new FormControl('$'),
      contentType: new FormControl('Image'),
      food_provider_email: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      hashtag: new FormControl(''),
      participantInput: new FormControl(''),
      mediaInput: new FormControl('', [Validators.maxLength(1000)]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      start_time: new FormControl('', [Validators.required]),
      end_time: new FormControl('', [Validators.required]),
      category: this.formBuilder.array(this.categories.map(x => !1)),
    });
  }

  ngOnInit(): void {
    if (this._Activatedroute.snapshot.params.flowType === 'edit') {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
    let event = this.foodProviderService.getCurrentEvent();
    this.currentUserDetails = this.foodProviderService.getUserDetails();
    this.currentEvent = event;
    // //console.log(event);
    if (event.fields !== undefined) {
      if (event.fields.food_provider_and_location) {
        let address = event.fields.food_provider_and_location.split('_')
        event.fields['address'] = address[1]
      }
      if (event.fields.media) {
        this.mediaArr = JSON5.parse(event.fields.media)
      }
      if (event.fields.start_date) {
        let sd = event.fields.start_date;
        event.fields['start_date'] = sd.substring(0, 10)
        event.fields['start_time'] = sd.substring(11, 16)
      }
      if (event.fields.end_date) {
        let ed = event.fields.end_date;
        event.fields['end_date'] = ed.substring(0, 10)
        event.fields['end_time'] = ed.substring(11, 16)
      }
      if (event.fields.category) {
        let categories = event.fields.category
        let newCategories: any = []
        this.categories.forEach((element) => {
          newCategories.push(categories.includes(element.ConfigValue))
        })
        event.fields['category'] = newCategories
      }
      if (event.fields.participants) {
        this.selectedParticipants = JSON5.parse(event.fields.participants)
      }
      this.eventForm.patchValue(event.fields);
    }
  }
  convertDate() {
    let sd = this.eventStartDate;
    let final_start_date = moment(sd).local().format("MMM DD, YYYY");
    let ed = this.eventEndDate;
    let final_end_date = moment(ed).local().format("MMM DD, YYYY");
    return final_start_date + ' to ' + final_end_date;
  }
  searchParticipants(searchTerm: any) {
    //console.log(searchTerm);
    if (searchTerm !== '' && searchTerm.charAt(0) !== '#') {
      this.foodProviderService.getAllSuggestions(searchTerm).subscribe((response) => {
        // //console.log('getAllSuggestions', response)
        this.participantSuggestions = response.suggest.suggestions
      })
    }
  }
  addParticipants(item: any) {
    if (this.selectedParticipants.filter((obj: any) => obj.id == item.id).length == 0) {
      this.selectedParticipants.push({ 'id': item.id, 'name': item.suggestion})
      this.selectedParticipantsToDisplay.push({'name': item.suggestion})
    this.participantSuggestions = [];
    } else {
      alert('duplicate id')
    }
  }
  removeParticipant(index: any){
    this.selectedParticipants.splice(index, 1);
    this.selectedParticipantsToDisplay.splice(index, 1);
  }
  validateHashTag() {
    let userHashtagInput = this.eventForm.value.hashtag;
    let realmEventData = this.currentEvent;
    //console.log('validateHashTag', userHashtagInput);
    if (userHashtagInput !== '') {
      this.foodProviderService.validateHashtag(userHashtagInput).subscribe(
        (response: any) => {
          //console.log('apiresponse after validateHashTag', response.hits.hit[0]);
          if (response !== undefined && response.hits.hit !== undefined && response.hits.hit[0] !== undefined) {
            let hashtagBackend = response.hits.hit[0].fields.hashtag;
            if (this.editMode) {
              if (realmEventData.fields.hashtag.toLowerCase() === userHashtagInput.toLowerCase()) {
                //console.log('1st if')
                this.duplicateHashtag = false;
                this.disableButton = false;
              } else if (userHashtagInput.toLowerCase() === hashtagBackend.toLowerCase()) {
                //console.log('1st else')
                this.duplicateHashtag = true;
                this.disableButton = true;
              }
            } else {
              if (userHashtagInput.toLowerCase() === hashtagBackend.toLowerCase()) {
                this.duplicateHashtag = true;
                this.disableButton = true;
              } else {
                this.duplicateHashtag = false;
                this.disableButton = false;
              }
            }
          } else if (response.hits.hit[0] === undefined) {
            this.duplicateHashtag = false;
            this.disableButton = false;
          }
        },
        error => {

        });
    }
  }
  changeBoolean() {
    this.disableButton = true;
  }
  onSubmit() {
    this.isLoading = true;
    let realmEventData = this.currentEvent;
    const { name, description, subtitle, website, display_phone, address, location_city, location_country,
      location_state, coordinates, budget, food_provider_email, hashtag, start_date, end_date,location_zip, start_time, end_time, category } = this.eventForm.value;
    let location_display_address = [address + (location_city != '' ? (", " + location_city) : '') +
      (location_state != '' ? (", " + location_state) : '') + (location_zip != '' ? (" " + location_zip) : '')]
      //console.log(location_display_address)
        let selectedCategories: Array<string> = [];
    category.forEach((element: boolean, index: number) => {
      if (element) {
        selectedCategories.push(this.categories[index].ConfigValue);
      }
    });
    this.eventStartDate = start_date + 'T' + start_time + ':00Z';
    this.eventEndDate = end_date + 'T' + end_time + ':00Z';
    let eventObject = {
      'name': name,
      'subtitle': subtitle,
      'description': description,
      'hashtag': hashtag,
      'food_provider_email': food_provider_email,
      'website': website,
      'display_phone': display_phone,
      'location_display_address': JSON.stringify(location_display_address),
      'location_city': location_city,
      'location_state': location_state,
      'location_country': location_country,
      'coordinates': coordinates,
      'budget': budget,
      'media': JSON.stringify(this.mediaArr),
      'start_date': this.eventStartDate,
      'end_date': this.eventEndDate,
      'record_type': 'Event',
      'participants': JSON.stringify(this.selectedParticipants),
      'duration': this.convertDate(),
      'createdBy': this.currentUserDetails.attributes.preferred_username,
      'updatedBy': this.currentUserDetails.attributes.preferred_username,
      'food_provider_and_location': name + '_' + address,
      'category': selectedCategories,
    }
    //console.log('constructed eventObject', Object.keys(eventObject).length);
      this.insertEvent(eventObject);
  }
  insertEvent(eventObject: any){
    this.foodProviderService.addEvent(eventObject).subscribe(
      (response: any) => {
        //console.log('apiresponse after submit', response);
        if (response === 'success') {
             this.isLoading = false
          Swal.fire({
            text: 'Your changes will be visible in the mobile app within 5 minutes.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/']);
              }
            })
        }
      },
      error => {
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      });
  }
  addMedia() {
    if (this.eventForm.value.mediaInput !== '') {
      if (this.mediaArr.filter((item: any) => item.url == this.eventForm.value.mediaInput).length == 0) {
        this.mediaArr.push({ type: this.eventForm.value.contentType, url: this.eventForm.value.mediaInput, source: 'Trofimo' });
        this.eventForm.patchValue({ mediaInput: '' });
        ////console.log('this.mediaArr', this.mediaArr);
      } else {
        alert('Duplicate file')
      }
    }
  }
  removeImage(media: any) {
    this.mediaArr.splice(this.mediaArr.findIndex(item => item.url === media.url), 1);
  }
  removeLocalImage(ref: any, index: any) {
    this.previews.splice(this.previews.findIndex(item => item === ref), 1);
  }
  uploadImages = async () => {
    this.imagesUploading = true;
    let localFiles = this.previews;
    let imageNames = this.selectedFiles;
    for (let i = 0; i < localFiles.length; i++) {
      const imageUploading = await this.s3Upload(localFiles[i], imageNames[i].name);
    }
  }
  async s3Upload(image: any, filename: any) {
    let imageName = filename;
    let id = this.eventForm.value.name + '_' + this.eventForm.value.address;
    let eventId = id.replace(/[^a-zA-Z0-9]/g, '_');
    let pathForStoring = 'webupload/events/' + eventId + '/media/images/';
    let imageUrl = environment.s3path + 'events/' + eventId + '/media/images/' + imageName;
    const response = await fetch(image);
    const blob = await response.blob();
    Storage.put(pathForStoring + imageName, blob, {
      bucket: environment.bucketName,
      level: 'public',
      acl: 'public-read',
    }).then(() => {
      // //console.log('uploaded successfully');
      this.selectedFiles = [];
      this.previews = [];
      this.mediaArr.push({ type: this.eventForm.value.contentType, url: imageUrl, source: 'Trofimo' });
      this.imagesUploading = false;
      // //console.log('final output of mediaArr', this.mediaArr);
    }).catch((error) => {
      //console.log('error while uploading' + error);
    });
  }
  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    // //console.log('image metadata', event.target.files);
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // //console.log('complete event', e);
          // //console.log('blob file', e.target.result);
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  get name() { return this.eventForm.get('name'); }
  get email() { return this.eventForm.get('food_provider_email'); }
  get address() { return this.eventForm.get('address'); }
  get hashtag() { return this.eventForm.get('hashtag'); }
  get mediaInput() { return this.eventForm.get('mediaInput'); }
  get startTime() { return this.eventForm.get('start_time'); }
  get endTime() { return this.eventForm.get('end_time'); }

}
