<div class="container mt-5">
    <form [formGroup]="eventSearchForm" #formDir="ngForm">
        <div class="mb-3 col-3">
            <label for="Hashtag" class="form-label">Event search</label>
            <input type="text" class="form-control" formControlName="searchTerm" id="searchTerm" placeholder="Search with hashtag" required (ngModelChange)="searchEvent($event)">
            <div *ngIf="suggestionItem !== '' && searchTerm !== ''">
                <li *ngFor="let record of suggestionItem; index as i" class="list-group-item list-group-item-action" (click)="onClickEventSuggestion(record)">{{record.suggestion}}</li>
            </div>
        </div>
        <div style="width: 220px;" *ngIf="noResultsFound" class="alert alert-danger mt-2">
            No results found.
        </div>
        <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !enableButton}" [disabled]="!enableButton" (click)="editEvent()">EDIT</button>
    </form>
</div>