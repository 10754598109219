export const environment = {
  production: false,
  bucketName: 'trofimo-static-files',
  s3path:
    'https://trofimo-static-files.s3.us-west-2.amazonaws.com/public/webupload/',
  xApiKey: 'uf0mHmjM6p1fpqkQSQ3992dUoudDswab74eobu4v',
  foodProviderCloudSearch: `https://api.dev.dinery.trofimoinc.com/searchdomain?q.parser=structured&size=10&q=(and 'name_placeholder' record_type:'Restaurant' location_city:'city_placeholder')`,
  dishOrAddons: `https://api.dev.dinery.trofimoinc.com/searchdomain?size=600&q=(and food_provider_and_location:'name_placeholder') &fq=(and record_type:'rtype')&return=_all_fields&q.parser=structured`,
  dishesAndAddons: `https://api.dev.dinery.trofimoinc.com/searchdomain?size=600&return=_all_fields&q.parser=structured&q=matchall&fq=(and (or record_type:'Addon' record_type:'Dish') food_provider_and_location:'name_placeholder')`,
  yelpAPIkey:
    '_VlEZJORUnbsjlwKNWy7nKsrpWwq6884tJngwUb1rRO594gVX64H61c4nHvjdiXSQL4-AXK-rs5bEeHP8pvw_Ih1QiBrLaRwyMjFYYQ4KtP0I7ptlTSBf5uIKRmZX3Yx',
  addRestaurant: 'https://api.dev.dinery.trofimoinc.com/dineryweb/insertfoodprovider',
  addDish: 'https://api.dev.dinery.trofimoinc.com/dineryweb/insertdish',
  getMenus:
    'https://api.dev.dinery.trofimoinc.com/fooditem/getmenu?Name=name_placeholder&Key=food_provider_and_location',
  addMenu: 'https://api.dev.dinery.trofimoinc.com/dineryweb/insertmenu',
  deleteDishOrMenuOrEvent: 'https://api.dev.dinery.trofimoinc.com/dineryweb/deletedishormenu',
  getMultipleConfig: 'https://api.dev.dinery.trofimoinc.com/config/getspecific',
  FILTER_CONFIG: [
    'PaymentOptions',
    'DiningOptions',
    'Quick Filters',
    'Cuisine',
    'Eating Style',
    'DishClassification',
    'Tag'
  ],
  insertEvent: 'https://api.dev.dinery.trofimoinc.com/dineryweb/insertevent',
  insertList: 'https://api.dev.dinery.trofimoinc.com/dineryweb/addusercuratedlist',
  updateList: 'https://api.dev.dinery.trofimoinc.com/dineryweb/updatecuratedlist',
  validateHashtag: `https://api.dev.dinery.trofimoinc.com/searchdomain?size=1&return=_all_fields&q.parser=structured&q=matchall&fq=(and hashtag:'hashtagTerm')`,
  eventSuggestions:
    'https://api.dev.dinery.trofimoinc.com/searchsuggest?suggester=hashtag&q=searchterm',
  allSuggestions:
    'https://api.dev.dinery.trofimoinc.com/searchsuggest?suggester=name&q=searchterm',
  getItembyId: `https://api.dev.dinery.trofimoinc.com/searchdomain?q.parser=structured&return=_all_fields&return=_all_fields&q=matchall&fq=(or _id:'item_id')`,
  listSearch:
    'https://api.dev.dinery.trofimoinc.com/fooditem/searchcuratedlists',
  deleteList: 'https://api.dev.dinery.trofimoinc.com/social/usercuratedlist',
  getTag: 'https://api.dev.dinery.trofimoinc.com/feedback/get-one-tag',
  approveTag: 'https://api.dev.dinery.trofimoinc.com/feedback/approve-tag',
  deleteTag: 'https://api.dev.dinery.trofimoinc.com/feedback/delete-tag',
  getPendingTags: 'https://api.dev.dinery.trofimoinc.com/feedback/get-pending-tags',
  addTagsInConfig: 'https://api.dev.dinery.trofimoinc.com/feedback/add-tags-in-config',
  itemsWithRT: `https://api.dev.dinery.trofimoinc.com/searchdomain?q.parser=structured&return=_all_fields&q=matchall&size=500&fq=(and record_type: 'rtype')`,
  allRecords: `https://api.dev.dinery.trofimoinc.com/searchdomain?return=name,record_type,location_city,food_provider_and_location&q=searchInput&size=100`,
  addTagsInItems: `https://api.dev.dinery.trofimoinc.com/feedback/add-tags-to-items`,
  yelpProxyFromLambda:
    'https://api.dev.dinery.trofimoinc.com/feedback/yelp-search',
  yelpRestaurantByIdFromLambda:
    'https://api.dev.dinery.trofimoinc.com/feedback/yelp-restaurant-by-id',
  googlePlaceSuggestionsFromLambda:
    'https://api.dev.dinery.trofimoinc.com/feedback/google-place-suggestions',
  googleImagesFromLambda:
    'https://api.dev.dinery.trofimoinc.com/feedback/google-images',
  deleteTagsInConfig: 'https://api.dev.dinery.trofimoinc.com/feedback/delete-tags-in-config',
};