import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { FormBuilder, FormGroup, FormArray, FormControl, FormControlName } from '@angular/forms'; import { Observable, of, throwError } from "rxjs";
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-food-provider-attributes',
  templateUrl: './food-provider-attributes.component.html',
  styleUrls: ['./food-provider-attributes.component.css']
})
export class FoodProviderAttributesComponent implements OnInit {

  foodProviderAttributes: any;
  currentRestaurant: any;
  budgetList: Array<string> = ['$​', '$$', '$$$', '$$$$'];
  isLoading: boolean = false;
  flowType: any = '';
  diningOptions: Array<any> = [];
  paymentOptions: Array<any> = [];
  features: Array<any> = [];
  categories: Array<any> = [];
  tags: any = [];
  tagsObj: any = {};
  tagSuggestionsOnChange: any = [];
  tagSuggestionsList: Array<any> = [];
  constructor(private foodProviderService: FoodProviderService,
    private router: Router,
    private fb: FormBuilder) {
    let configData = this.foodProviderService.getConfigData();
    this.diningOptions = JSON.parse(configData).DiningOptions;
    this.paymentOptions = JSON.parse(configData).PaymentOptions;
    this.tagSuggestionsList = JSON.parse(configData).Tag;
    this.features = JSON.parse(configData).QuickFilters;
    this.categories = JSON.parse(configData).Cuisine;
    this.foodProviderAttributes = new FormGroup({
      amenities: this.fb.array(this.features.map(x => !1)),
      dining_options: this.fb.array(this.diningOptions.map(x => !1)),
      payment_options: this.fb.array(this.paymentOptions.map(x => !1)),
      budget: new FormControl(''),
      category: this.fb.array(this.categories.map(x => !1)),
      tagName: new FormControl(''),
    });
  }
  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    //this.diningOptions = dinOptions.map((str: { ConfigValue: any; }) => ({ name: str.ConfigValue, value: str.ConfigValue }));
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    if (this.currentRestaurant.fields.amenities) {
      let amenities = this.currentRestaurant.fields.amenities
      let newAmenities: any = []
      this.features.forEach((element) => {
        newAmenities.push(amenities.includes(element.ConfigValue))
      })
      this.foodProviderAttributes.patchValue({ amenities: newAmenities });
    }
    if (this.currentRestaurant.fields.dining_options) {
      let diningOptions = this.currentRestaurant.fields.dining_options
      let newDiningOptions: any = []
      this.diningOptions.forEach((element) => {
        newDiningOptions.push(diningOptions.includes(element.ConfigValue))
      })
      this.foodProviderAttributes.patchValue({ dining_options: newDiningOptions });
    }
    if (this.currentRestaurant.fields.payment_options) {
      let paymet = this.currentRestaurant.fields.payment_options
      let newPayment: any = []
      this.paymentOptions.forEach((element) => {
        newPayment.push(paymet.includes(element.ConfigValue))
      })
      this.foodProviderAttributes.patchValue({ payment_options: newPayment });
    }
    if (this.currentRestaurant.fields.category) {
      let categories = this.currentRestaurant.fields.category
      let newCategories: any = []
      this.categories.forEach((element) => {
        newCategories.push(categories.includes(element.ConfigValue))
      })
      this.foodProviderAttributes.patchValue({ category: newCategories });
    }
    if (this.currentRestaurant.fields.budget) {
      this.foodProviderAttributes.patchValue({ budget: this.currentRestaurant.fields.budget });
    }
    if (this.currentRestaurant.fields.tags) {
      this.tags = JSON.parse(this.currentRestaurant.fields.tags)
    }

  }
  tagSuggestions(tagname: any) {
    let sampleArray: any = [];
    if (tagname !== '') {
      this.tagSuggestionsList.forEach(item => {
        if (item.ConfigValue.toLowerCase().startsWith(tagname.toLowerCase())) {
          sampleArray.push( item.ConfigValue);
        }
        this.tagSuggestionsOnChange = [...new Set(sampleArray)]
      });
    }
  }
  onClickBtn() {
    this.isLoading = true;
    const { amenities, dining_options, payment_options, budget, category } = this.foodProviderAttributes.value
    this.currentRestaurant.fields['amenities'] = JSON.stringify(amenities);
    this.currentRestaurant.fields['dining_options'] = JSON.stringify(dining_options);
    this.currentRestaurant.fields['category'] = JSON.stringify(category);
    this.currentRestaurant.fields['source'] = 'Trofimo';
    this.currentRestaurant.fields['record_type'] = 'Restaurant';
    this.currentRestaurant.fields['tags'] = JSON.stringify(this.tags)

    if (budget) {
      this.currentRestaurant.fields['budget'] = budget;
      let price;
      if (budget === '$') {
        price = 10;
      } else if (budget === '$$') {
        price = 30;
      } else if (budget === '$$$') {
        price = 60;
      } else if (budget === '$$$$') {
        price = 70;
      }
      this.currentRestaurant.fields['price'] = price;
    }
    if (this.currentRestaurant.fields.image_from_provider) {
      this.currentRestaurant.fields['image_from_provider'] = '';
    }
    if (this.currentRestaurant.fields.provider_alias) {
      this.currentRestaurant.fields['provider_alias'] = '';
    }
    let paymentOption: Array<string> = [];
    payment_options.forEach((element: boolean, index: number) => {
      if (element) {
        paymentOption.push(this.paymentOptions[index].ConfigValue);
      }
    });
    this.currentRestaurant.fields['payment_options'] = paymentOption;
    let diningOptions: Array<string> = [];
    dining_options.forEach((element: boolean, index: number) => {
      if (element) {
        diningOptions.push(this.diningOptions[index].ConfigValue);
      }
    });
    this.currentRestaurant.fields['dining_options'] = diningOptions;
    let selectedCategories: Array<string> = [];
    category.forEach((element: boolean, index: number) => {
      if (element) {
        selectedCategories.push(this.categories[index].ConfigValue);
      }
    });
    this.currentRestaurant.fields['category'] = selectedCategories;
    let amenitiesArr: Array<string> = [];
    amenities.forEach((element: boolean, index: number) => {
      if (element) {
        amenitiesArr.push(this.features[index].ConfigValue);
      }
    });
    this.currentRestaurant.fields['amenities'] = amenitiesArr;
    if (this.tags?.length != 0) {
      const arrayOfStrings = this.tags.map((item: { tagname: string; }) => item.tagname);
      this.foodProviderService.addTagsInConfig({tags: arrayOfStrings}).subscribe();
    }
    this.foodProviderService.setCurrentRestaurant(this.currentRestaurant);
    this.foodProviderService.addRestaurant(this.currentRestaurant.fields).subscribe((resp) => {
      this.isLoading = false;
      if (resp === 'success') {
        this.foodProviderAttributes.reset();
        this.isLoading = false;
        Swal.fire({
          text: 'Your changes will be visible in the mobile app within 5 minutes.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['dishlist']);
          }
        })
      } else {
        this.isLoading = false;
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      }
    })
  }

  get dining_options() { return this.foodProviderAttributes.get('dining_options') as FormArray; }

  get payment_options() { return this.foodProviderAttributes.get('payment_options') as FormArray; }

  get amenities() { return this.foodProviderAttributes.get('amenities') as FormArray; }
  get tagName() { 
    return this.foodProviderAttributes.get('tagName'); }

  removeTag(key: any) {
    this.tags.splice(key, 1);
  }
  addTag(tagName: any) {
    if (tagName !== '') {
      if (this.tags.filter((item: any) => item.tagname == tagName).length == 0) {
        this.tagsObj = {}
        this.tagsObj['tagname'] = tagName
        this.tagsObj['count'] = 1
        this.tags.push(this.tagsObj)
      } else {
        alert('Tag already added.')
      }
    }
    this.foodProviderAttributes.patchValue({ tagName: '' });
    this.tagSuggestionsOnChange = [];
  }
}
