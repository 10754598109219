import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
} from 'amazon-cognito-identity-js';
import * as AWS from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
// import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLogin = false;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    roleAs: any;

    constructor() {
        this.roleAs = "";
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public async login(username, password) {
        try {
            const user = await Auth.signIn(username, password);
            let a = await user.getSignInUserSession();
            //console.log(a)
            //console.log(a.accessToken.payload['cognito:groups'])
            // console.log('User', user.getSignInUserSession());
            //console.log('User', user);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            // console.log('User', user['attributes']);
            if(a.accessToken.payload['cognito:groups'].indexOf("TrofimoStaff") !== -1) {
                this.isLogin = true;
                this.roleAs = "TrofimoStaff";
                localStorage.setItem('STATE', 'true');
                localStorage.setItem('ROLE', this.roleAs);
            }
            else{
                alert("User is not eligible for login!")
                this.isLogin = false;
                localStorage.setItem('STATE', 'false');
                localStorage.setItem('ROLE', this.roleAs);
            }
        } catch (error) {
            localStorage.clear();
            //   this.router.navigate(['/foodproviderselect']);
            console.error('error signing in', error);
        }
        return { success: this.isLogin, role: this.roleAs };
    }

    public async logout() {
        try{
        await Auth.signOut();
        // const user = await Auth.currentAuthenticatedUser();
        // //console.log(user)
        this.isLogin = false;
        this.roleAs = 'a';
        localStorage.setItem('STATE', 'false');
        localStorage.setItem('ROLE', this.roleAs);
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        }
        catch (error) {
            //   this.router.navigate(['/foodproviderselect']);
            localStorage.clear();
            console.error('error signing in', error);
        }
        return { success: this.isLogin, role: '' };
    }

    isLoggedIn() {
        let currenLoggedIntUser = this.currentUserValue;
        // console.log("currentUser", currenLoggedIntUser)
        if (currenLoggedIntUser && currenLoggedIntUser.signInUserSession) {
            return true;
        }
        return false;
    }

    getRole() {
        this.roleAs = localStorage.getItem('ROLE');
        return this.roleAs;
    }
}
