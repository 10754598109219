import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HomeComponent } from './core/components/home/home.component';
import { FoodProviderStartComponent } from './core/components/food-provider-start/food-provider-start.component';
import { FoodProviderSelectComponent } from './core/components/food-provider-select/food-provider-select.component';
import { FoodProviderInfoComponent } from './core/components/food-provider-info/food-provider-info.component';
import { FoodProviderLocationComponent } from './core/components/food-provider-location/food-provider-location.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FoodProviderHoursComponent } from './core/components/food-provider-hours/food-provider-hours.component';
import { FoodProviderAttributesComponent } from './core/components/food-provider-attributes/food-provider-attributes.component';
import { DishEntryComponent } from './core/components/dish-entry/dish-entry.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DishListComponent } from './core/components/dish-list/dish-list.component';
import { FoodMenuComponent } from './core/components/food-menu/food-menu.component';
import { FoodMenuFormComponent } from './core/components/food-menu-form/food-menu-form.component';
import { AuthComponent } from './auth/auth.component';
import { LocalStorageService } from 'ngx-webstorage';
import awsconfig from '../aws-exports';
import { NgxPaginationModule } from "ngx-pagination";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { FoodProviderMediaComponent } from './core/components/food-provider-media/food-provider-media.component';
import { EventEntryComponent } from './core/components/event-entry/event-entry.component';
import { EventSearchComponent } from './core/components/event-search/event-search.component';
import { ListEntryComponent } from './core/components/list-entry/list-entry.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { ListSearchComponent } from './core/components/list-search/list-search.component';
import { AdminApprovalComponent } from './core/components/admin-approval/admin-approval.component';
import { ReviewTagComponent } from './core/components/review-tag/review-tag.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterPipe } from './core/components/dish-list/filter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AddTagsComponent } from './core/components/add-tags/add-tags.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

/* Configure Amplify resources */
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FoodProviderStartComponent,
    FoodProviderSelectComponent,
    FoodProviderInfoComponent,
    FoodProviderLocationComponent,
    FoodProviderHoursComponent,
    FoodProviderAttributesComponent,
    DishEntryComponent,
    AuthComponent,
    DishListComponent,
    FoodMenuComponent,
    FoodMenuFormComponent,
    FoodProviderMediaComponent,
    EventEntryComponent,
    EventSearchComponent,
    ListEntryComponent,
    ListSearchComponent,
    AdminApprovalComponent,
    ReviewTagComponent,
    FilterPipe,
    AddTagsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatSelectModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    LocalStorageService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
