import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import { environment } from 'src/environments/environment';
import { Storage } from 'aws-amplify';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

const JSON5 = require('json5');

@Component({
  selector: 'app-food-provider-media',
  templateUrl: './food-provider-media.component.html',
  styleUrls: ['./food-provider-media.component.css']
})
export class FoodProviderMediaComponent implements OnInit {
  foodProviderMediaForm: any;
  currentRestaurant: any;
  flowType: any = '';
  imagesUploading: boolean = false;
  fetchImages: boolean = false;
  selectedFiles: any[] = [];
  mediaArr: any = [];
  previews: string[] = [];
  contentList: Array<string> = ['Image', 'Video'];



  constructor(private foodProviderService: FoodProviderService,
    private router: Router) {
    this.foodProviderMediaForm = new FormGroup({
      mediaInput: new FormControl('', [Validators.maxLength(1000)]),
      media: new FormControl(''),
      contentType: new FormControl('Image'),
    });
  }
  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    if (this.currentRestaurant.fields !== undefined) {
      if (this.currentRestaurant.fields.media !== undefined) {
        this.mediaArr = JSON5.parse(this.currentRestaurant.fields.media);
      }
    }
  }
  removeImage(media: any) {
    this.mediaArr.splice(this.mediaArr.findIndex(item => item.url === media.url), 1);
  }
  removeLocalImage(ref: any, index: any) {
    this.previews.splice(this.previews.findIndex(item => item === ref), 1);
  }
  get mediaInput() {
    return this.foodProviderMediaForm.get('mediaInput');
  }
  addMedia() {
    if (this.foodProviderMediaForm.value.mediaInput !== '') {
      if (this.mediaArr.filter((item: any) => item.url == this.foodProviderMediaForm.value.mediaInput).length == 0) {
        this.mediaArr.push({ type: this.foodProviderMediaForm.value.contentType, url: this.foodProviderMediaForm.value.mediaInput, source: 'Trofimo' });
        this.foodProviderMediaForm.patchValue({ mediaInput: '' });
      } else {
        alert('Duplicate')
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.mediaArr, event.previousIndex, event.currentIndex);
  }
  fetchGoogleImages() {
      this.fetchImages = true;
      this.foodProviderService.getGoogleImagesFromLambda(this.currentRestaurant.fields.name).subscribe((imageResponse) => {
        if (imageResponse) {
          this.modifyImageData(imageResponse.image_results.sort(() => Math.random() - 0.5).slice(0, 6));
        }
      })
  }
  modifyImageData(imageData: any) {
    for (let i = 0; i < imageData.length; i++) {
      this.mediaArr.push({ type: 'Image', url: imageData[i].sourceUrl, source: imageData[i].source, blurImage: true });
    }
    this.fetchImages = false;
  }
  uploadImages = async () => {
    this.imagesUploading = true;
    let localFiles = this.previews;
    let imageNames = this.selectedFiles;
    for (let i = 0; i < localFiles.length; i++) {
      const imageUploading = await this.s3Upload(localFiles[i], imageNames[i].name);
    }
  }
  async s3Upload(image: any, filename: any) {
    let imageName = filename;
    let id = this.currentRestaurant.fields.name + '_' + this.currentRestaurant.fields.location_address_1;
    let restaurantId = id.replace(/[^a-zA-Z0-9]/g, '_');
    let pathForStoring = 'webupload/foodproviders/' + restaurantId + '/media/images/';
    let imageUrl = environment.s3path + 'foodproviders/' + restaurantId + '/media/images/' + imageName;
    const response = await fetch(image);
    const blob = await response.blob();
    Storage.put(pathForStoring + imageName, blob, {
      bucket: environment.bucketName,
      level: 'public',
      acl: 'public-read',
    }).then(() => {
      // console.log('uploaded successfully');
      this.selectedFiles = [];
      this.previews = [];
      this.mediaArr.push({ type: this.foodProviderMediaForm.value.contentType, url: imageUrl, source: 'Trofimo' });
      this.imagesUploading = false;
      // console.log('final output of mediaArr', this.mediaArr);
    }).catch((error) => {
      console.log('error while uploading' + error);
    });
  }
  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    // console.log('image metadata', event.target.files);
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // console.log('complete event', e);
          // console.log('blob file', e.target.result);
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
  onSubmit() {
    let currentRestaurantString = localStorage.getItem('currentRestaurant');
    let currentRestaurant = currentRestaurantString ? JSON.parse(currentRestaurantString) : {};
    if (currentRestaurant.fields === undefined) {
      currentRestaurant['fields'] = {}
    }
    currentRestaurant['fields']['media'] = JSON.stringify(this.mediaArr);
    this.foodProviderService.setCurrentRestaurant(currentRestaurant)
    this.router.navigate(['/foodproviderhours']);
  }
}
