<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderhours">
                &nbsp; FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderattributes">
                &nbsp; FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/dishlist">
                &nbsp; Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5">&nbsp; Menu</span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line">
    <form [formGroup]="menuForm" class="mb-5">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="mb-3 col-7 col-md-10">
                    <label for="name" class="form-label">
                        Menu name
                    </label>
                    <input [readonly]="isEditForm" formControlName="name" type="text" class="form-control" id="name" placeholder="Input name" required>
                </div>
                <div class="mb-3 col-7 col-md-10">
                    <label for="description" class="form-label">
                        Menu description
                    </label>
                    <input formControlName="description" type="text" class="form-control" id="description" placeholder="Input description">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="mb-3 col-7 col-md-10">
                    <label for="address1" class="form-label" (click)="toggleIcon()">
                        Menu open hours
                    </label>
                    <div>
                        <div>
                            <span>Monday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 0"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Tuesday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 1"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Wednesday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 2"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Thursday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 3"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Friday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 4"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Saturday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 5"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <span>Sunday &nbsp;</span>
                            <span *ngFor="let item of hours[0].open; index as i">
                                <span
                                    *ngIf="item.day === 6"
                                    style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                                >
                                    <span class="p-2">
                                        {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                    </span>
                            <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                        X
                                    </span>
                            </span>
                            </span>
                        </div>
                        <div>
                            <div class="input-group mb-3 mt-3">
                                <select class="form-select" formControlName="hoursFromWeekDay" aria-label="Default select example" required>
                                    <option
                                        selected
                                        value
                                        hidden
                                    >
                                        Select day
                                    </option>
                                    <option value="0">Monday</option>
                                    <option value="1">Tuesday</option>
                                    <option value="2">Wednesday</option>
                                    <option value="3">Thursday</option>
                                    <option value="4">Friday</option>
                                    <option value="5">Saturday</option>
                                    <option value="6">Sunday</option>

                                </select>
                                <select class="form-select" formControlName="hoursToWeekDay" aria-label="Default select example" required>
                                    <option
                                        selected
                                        value
                                        hidden
                                    >
                                        Select day
                                    </option>
                                    <option value="0">Monday</option>
                                    <option value="1">Tuesday</option>
                                    <option value="2">Wednesday</option>
                                    <option value="3">Thursday</option>
                                    <option value="4">Friday</option>
                                    <option value="5">Saturday</option>
                                    <option value="6">Sunday</option>
                                </select>
                                <input type="time" formControlName="hoursFromTime" class="form-control" required>
                                <input type="time" formControlName="hoursToTime" class="form-control" required>
                                <button [disabled]="!isHoursFormValid" class="btn btn-outline-secondary" type="button" (click)="addHours()">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <p style="margin-bottom: 15px; width: 150px;" [ngClass]="{'link-text': true}" (click)="addSubmenu()">
            Add menu section
        </p>
        <div formArrayName="submenus">
            <div *ngFor="let submenu of submenus.controls; let i=index" [formGroupName]="i" class="row" style="align-items: center;">
                <div class="col-1">
                    <h3>
                        <span class="badge bg-secondary">{{i+1}}</span>
                    </h3>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <label for="sectionName" class="form-label">
                                    Menu section name
                                </label>
                                <input formControlName="name" type="text" class="form-control" [id]="'sectionName'+i" placeholder="Input name">
                            </div>
                        </div>
                    </div>
                    <div>
                        <label for="list" class="form-label mt-2">
                            Select dishes
                        </label>
                        <div>
                            <mat-form-field appearance="fill" class='col-12'>
                                <mat-label>Select dishes</mat-label>
                                <mat-select formControlName="list" multiple>
                                    <mat-option *ngFor="let dish of recommendedDishes" [value]="dish.fields.name">
                                        {{dish.fields.name}}<strong>{{getRecordType(dish.fields.record_type)}}</strong>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <button [disabled]="submenus.controls.length===1" type="button" (click)="removeSubmenu(i)" class="btn-close" aria-label="Close"></button>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
        <ng-template #thenBlockBtn>
            <button class="save-btn-disabled mb-5 mt-5" type="button" disabled>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Loading...
            </button>
        </ng-template>
        <ng-template #elseBlockBtn>
            <button type="button" class="save-btn mb-5 mt-5" (click)="saveMenu()">
                SUBMIT
            </button>
        </ng-template>
    </form>
</div>