<div class="container mt-5">
    <div class="container" style="display: flex; align-items: center;">
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
            Start
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
            Select
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
            FP Info
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
            FP Location
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
            FP Media
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
            FP Hours
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
            FP Attributes
        </span>
        <span class="fs-5">-----</span>
        <div class="circle-selected"></div>
        <span class="fs-5 cursor ms-2" routerLink="/dishlist">
            Dish
        </span>
        <span class="fs-5">-----</span>
        <div class="circle"></div>
        <span class="fs-5 cursor ms-2" routerLink="/menulist">
            Menu
        </span>
    </div>
    <hr class="line">

    <div class="row">
        <div class="row mb-3" style="align-items: center;">
            <span class="col" style="font: 18; cursor: pointer;" routerLink="/dishlist">
                Dishes
            </span>
            <div class="col">
                <button type="submit" class="save-btn" style="float: right;" routerLink="/menuform">
                    ADD MENU
                </button>
            </div>
        </div>

        <div class="mt-3 col-6" *ngFor="let menu of menus; index as i">
            <p style="font: 18px;">{{menu.name}}</p>
            <p>{{menu.description}}</p>
            <div class="pb-2 ms-5" style="justify-content: center;" *ngFor="let menuSections of menuSections(menu.dishes); index as j">
                <a style="text-decoration: none; color: black; font-size: large; align-items: center;" data-bs-toggle="collapse" href="{{'#id_'+i+j}}" aria-expanded="false" aria-controls="collapseExample">

                    <!-- <span class="show" id="{{'id_'+i+j}}" style="font-size: large">+</span>
                    <span class="collapse" style="font-size: large" id="{{'id_'+i+j}}">-</span> -->
                    {{getKey(menuSections)}}
                </a>
                <div class="collapse mt-1 mb-1 ms-5" id="{{'id_'+i+j}}" *ngFor="let dish of getValue(menuSections); index as k">
                    <span>{{dish}}</span>
                </div>
            </div>
            <div class="mt-3 mb-5">
                <button type="button" (click)="edit(menu)" class="edit-btn">
                    EDIT
                </button>
                <button type="button" data-bs-toggle="modal" data-bs-target="#deleteModal" class="delete-btn" (click)="setMenuNameAndIndex(menu.name, i)">
                    <img
                        alt="delete_icon"
                        style="width: 14px; height: 14px;"
                        src="assets/images/delete_white 3.png"
                    >
                </button>
            </div>
        </div>
        <div *ngIf="isLoading">
            <div class="spinner-border text-warning mt-2" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading && menus.length === 0" class="alert alert-primary mt-5" role="alert">
        No results found.
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                    Are you sure you want to delete this menu?
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-footer">
                <button type="button" class="edit-btn" style="width: 90px;" data-bs-dismiss="modal">
                    CANCEL
                </button>
                <button type="button" class="edit-btn" style="width: 90px; color: #FFFFFF; background-color: #FF8A3B;" data-bs-dismiss="modal" (click)="deleteMenu()">
                    DELETE
                </button>
            </div>
        </div>
    </div>
</div>