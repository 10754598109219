import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.css']
})
export class AddTagsComponent implements OnInit {
  tagEntryForm: FormGroup;
  restRecords: { id: number, name: string }[] = [];
  dishRecords: { id: number, name: string }[] = [];
  selectedRests: { id: number, name: string }[] = [];
  selectedDishes: { id: number, name: string }[] = [];
  dropdownSettings = {};
  isLoading: boolean = false;
  constructor(private foodProviderService: FoodProviderService, private router: Router) {
    this.tagEntryForm = new FormGroup({
      tagName: new FormControl(''),
      searchInput: new FormControl(''),
    })
  }
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  filterRecords(searchTerm: string) {
    const dishEndPoint = `${environment.itemsWithRT.replace('rtype', 'Dish').replace('matchall', `'${searchTerm}'`)}`;
    const restEndPoint = `${environment.itemsWithRT.replace('rtype', 'Restaurant').replace('matchall', `'${searchTerm}'`)}`;
  
    forkJoin([
      this.foodProviderService.getItemsWithRT(dishEndPoint),
      this.foodProviderService.getItemsWithRT(restEndPoint)
    ]).pipe(
      map(([dishResponse, restaurantResponse]) => {
        if (dishResponse && dishResponse.hits && dishResponse.hits.hit) {
          this.dishRecords = this.formatData(dishResponse.hits.hit);
        } else {
          this.dishRecords = [];
        }
  
        if (restaurantResponse && restaurantResponse.hits && restaurantResponse.hits.hit) {
          this.restRecords = this.formatData(restaurantResponse.hits.hit);
        } else {
          this.restRecords = [];
        }
      })
    ).subscribe();
  }
  
  

  onSubmit(): void {
    const searchText = this.createEndPoint(this.selectedDishes.concat(this.selectedRests));
    const size = this.selectedDishes.concat(this.selectedRests);
    const itemLength = size.length;
    const { tagName } = this.tagEntryForm?.value ?? {};
    const reqBody = {
      searchText,
      itemLength: String(itemLength),
      tagName
    };
    this.isLoading = true;
    const tagArray = Array(itemLength).fill(tagName);
    this.foodProviderService.addTagsInConfig({tags: tagArray}).subscribe();
    this.foodProviderService.addTagsInItems(reqBody).subscribe((resp) => {
      this.isLoading = false;
      if (resp) {
        this.tagEntryForm.reset();
        Swal.fire({
          text: 'Your changes will be visible in the mobile app within 5 minutes.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/']);
          }
        });
      } else {
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });
      }
    });
  }
  
  onItemSelect(item: any, recordType: string): void {
    if (recordType === 'Restaurant') {
      this.selectedRests.push(item.id);
    } else if (recordType === 'Dish') {
      this.selectedDishes.push(item.id);
    }
  }
  onItemDeSelect(item: any, recordType: string): void {
    if (recordType === 'Restaurant') {
      this.selectedRests.splice(this.selectedRests.findIndex(ref => ref === item.id), 1);
    } else if (recordType === 'Dish') {
      this.selectedDishes.splice(this.selectedDishes.findIndex(ref => ref === item.id), 1);
    }
  }
  formatData(data: []): { id: number, name: string }[] {
    return data.map(({ id, fields }) => {
      return {
        id: id,
        name: fields['name'],
      };
    });
  }
  createEndPoint(data: any): string {
    let url = '';
    data.forEach((item: string) => {
      if (url === '') {
        url = `_id:'${item}'`;
      } else {
        url = `${url} _id:'${item}'`;
      }
    });
    return url;
  }
}
