<div class="container mt-5">
    <form [formGroup]="newTagsForm" #formDir="ngForm">
        <div class="row">
            <button type="button" class="pivot-btn-selected">Existing Tags</button>
        </div>
        <div class="d-flex flex-column mt-3 mb-3">
            <div class="d-flex flex-row flex-wrap">
                <span style="cursor: pointer;" *ngFor="let list of tagsConfigData" (click)="deleteTags(list)"
                    class="tag-style m-2 px-2 py-1">
                    {{ list.ConfigValue }}
                </span>
            </div>
            <div class="row" *ngIf="tagsConfigData.length === 0">
                <div class="alert alert-primary mt-5 col-md-4">
                    No tags in config
                </div>
            </div>
        </div>
        <div *ngIf="isLoading">
            <span class="spinner-border sspinner-border-xl" role="status" aria-hidden="true"></span>
        </div>
        <div *ngIf="tagsToBeDeleted.length === 0" class="input-group mb-3" style=" width: 340px">
            <input type="text" style=" margin-right: 20px;" formControlName="tagName" class="form-control" id="tagname"
                placeholder="Enter tag name">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="addNewTags()">
                    Add
                </button>
            </div>
        </div>
        <label *ngIf="tagsToBeDeleted.length > 0" for="Tags" class="form-label">
            Tags to be deleted?
        </label>
        <div class="d-flex flex-row flex-wrap">
            <span style="cursor: pointer;" *ngFor="let tag of tagsToBeDeleted" (click)="removeTag(tag, 'delete')"
                class="tag-style m-2 px-2 py-1">
                {{ tag }}
            </span>
        </div>
        <label *ngIf="newlyAddedTags.length > 0" for="Tags" class="form-label">
            Newly Added Tags
        </label>
        <div class="d-flex flex-row flex-wrap">
            <span style="cursor: pointer;" *ngFor="let tag of newlyAddedTags" (click)="removeTag(tag,'add')"
                class="tag-style m-2 px-2 py-1">
                {{ tag }}
            </span>
        </div>
        <button *ngIf="newlyAddedTags.length > 0 || tagsToBeDeleted.length > 0" (click)="handleAction()" type="button"
            [disabled]="newlyAddedTags.length === 0 && tagsToBeDeleted.length === 0 || isApiCallDone"
            [ngClass]="{'save-btn': true, 'disabled-btn': newlyAddedTags.length === 0 && tagsToBeDeleted.length === 0 || isApiCallDone}">
            <span *ngIf="isApiCallDone; else elseBlockContent">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
            </span>
            <ng-template #elseBlockContent>
                {{ getButtonLabel() }}
            </ng-template>
        </button>
    </form>
</div>