import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FoodProviderService } from '../../services/food-provider.service';

@Component({
  selector: 'app-food-provider-location',
  templateUrl: './food-provider-location.component.html',
  styleUrls: ['./food-provider-location.component.css']
})
export class FoodProviderLocationComponent implements OnInit {
  foodProviderLocationForm: any;
  currentRestaurant: any;
  flowType: any = '';


  constructor(private foodProviderService: FoodProviderService,
    private router: Router) {
    this.foodProviderLocationForm = new FormGroup({
      location_address_1: new FormControl('', [Validators.required]),
      location_address_2: new FormControl(''),
      location_address_3: new FormControl(''),
      location_city: new FormControl(''),
      location_country: new FormControl(''),
      location_cross_street: new FormControl(''),
      location_display_address: new FormControl(''),
      location_state: new FormControl(''),
      coordinates: new FormControl(''),
      location_zip_code: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.flowType = localStorage.getItem('flowType');
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    this.foodProviderLocationForm.patchValue(this.currentRestaurant.fields);
    //console.log("this.currentRestaurant.fields");
    //console.log(this.foodProviderService.getCurrentRestaurant());
  }
  get location1() {
    return this.foodProviderLocationForm.get('location_address_1');
  }
  saveRestaurant() {
    const { location_address_1, location_address_2, location_address_3, location_city, location_country, location_cross_street, location_display_address,
      location_state, coordinates, location_zip_code } = this.foodProviderLocationForm.value;

    this.currentRestaurant['fields']['location_address_1'] = location_address_1;
    this.currentRestaurant['fields']['location_address_2'] = location_address_2;
    this.currentRestaurant['fields']['location_address_3'] = location_address_3;
    this.currentRestaurant['fields']['location_city'] = location_city;
    this.currentRestaurant['fields']['location_country'] = location_country;
    this.currentRestaurant['fields']['location_cross_street'] = location_cross_street;
    this.currentRestaurant['fields']['location_display_address'] = location_display_address;
    this.currentRestaurant['fields']['location_state'] = location_state;
    this.currentRestaurant['fields']['coordinates'] = coordinates;
    this.currentRestaurant['fields']['location_zip_code'] = location_zip_code;
    this.currentRestaurant['fields']['source'] = 'Trofimo';
    this.currentRestaurant['fields']['record_type'] = 'Restaurant';
    this.foodProviderService.setCurrentRestaurant(this.currentRestaurant)
    this.router.navigate(['/foodprovidermedia']);
    //console.log(this.currentRestaurant);
  }

}
