<div class="container mt-5">
    <div *ngIf="flowType!=='edit'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderstart">
                &nbsp; Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderselect">
                &nbsp; Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderinfo">
                &nbsp; FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderlocation">
                &nbsp; FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor" routerLink="/foodprovidermedia">
                &nbsp; FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor" routerLink="/foodproviderhours">
                &nbsp; FP Hours
            </span>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="container" style="display: flex; align-items: center;">
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderstart">
                Start
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderselect">
                Select
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderinfo">
                FP Info
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderlocation">
                FP Location
            </span>
            <span class="fs-5">-----</span>
            <div class="circle-selected"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodprovidermedia">
                FP Media
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderhours">
                FP Hours
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/foodproviderattributes">
                FP Attributes
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/dishlist">
                Dish
            </span>
            <span class="fs-5">-----</span>
            <div class="circle"></div>
            <span class="fs-5 cursor ms-2" routerLink="/menulist">
                Menu
            </span>
        </div>
    </ng-template>

    <hr class="line">
    <form [formGroup]="foodProviderHours">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="mb-3 col-7 col-md-10">
                    <label for="address1" class="form-label">
                        Open hours
                    </label>
                    <div>
                        <span>Monday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 0"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Tuesday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 1"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Wednesday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 2"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Thursday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 3"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Friday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 4"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Saturday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 5"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <span>Sunday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span
                                *ngIf="item.day === 6"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;"
                            >
                                <span class="p-2">
                                    {{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}
                                </span>
                        <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">
                                    X
                                </span>
                        </span>
                        </span>
                    </div>
                    <div>
                        <div class="input-group mb-3 mt-3">
                            <select class="form-select" formControlName="hoursFromWeekDay" aria-label="Default select example" required>
                                <option
                                    selected
                                    value
                                    hidden
                                >
                                    Select day
                                </option>
                                <option value="0">Monday</option>
                                <option value="1">Tuesday</option>
                                <option value="2">Wednesday</option>
                                <option value="3">Thursday</option>
                                <option value="4">Friday</option>
                                <option value="5">Saturday</option>
                                <option value="6">Sunday</option>

                            </select>
                            <select class="form-select" formControlName="hoursToWeekDay" aria-label="Default select example" required>
                                <option
                                    selected
                                    value
                                    hidden
                                >
                                    Select day
                                </option>
                                <option value="0">Monday</option>
                                <option value="1">Tuesday</option>
                                <option value="2">Wednesday</option>
                                <option value="3">Thursday</option>
                                <option value="4">Friday</option>
                                <option value="5">Saturday</option>
                                <option value="6">Sunday</option>
                            </select>
                            <input type="time" formControlName="hoursFromTime" class="form-control" required>
                            <input type="time" formControlName="hoursToTime" class="form-control" required>
                            <button [disabled]="!isHoursFormValid" class="btn btn-outline-secondary" type="button" (click)="addHours()">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3 col-7 col-md-10">
                    <label for="address1" class="form-label">Special hours</label>
                    <div>
                        <span>Sunday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 0"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Monday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 1"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Tuesday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 2"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Wednessday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 3"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Thursday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 4"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Friday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 5"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span>Saturday &nbsp;</span>
                        <span *ngFor="let item of hours[0].open; index as i">
                            <span *ngIf="item.day === 6"
                                style="background-color: #FCF1EB ;border-radius: 8px; border: 1px solid black; margin-left: 10px;">
                                <span
                                    class="p-2">{{item.start.substring(0,2)}}:{{item.start.substring(2,4)}}-{{item.end.substring(0,2)}}:{{item.end.substring(2,4)}}</span>
                                    <span class="p-2" style="cursor: pointer;" (click)="removeHour(item, i)">X</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <div class="input-group mb-3 mt-3">
                            <select class="form-select" formControlName="specialHoursFromWeekDay" aria-label="Default select example" required>
                                <option selected value="">Select day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednessday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>
                            </select>
                            <select class="form-select" formControlName="specialHoursToWeekDay" aria-label="Default select example" required>
                                <option selected value="">Select day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednessday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>
                            </select>
                            <input type="time" formControlName="specialHoursFromTime" class="form-control" required>
                            <input type="time" formControlName="specialHoursToTime" class="form-control" required>
                            <button [disabled]="!isHoursFormValid" class="btn btn-outline-secondary" type="button" (click)="addHours()">Add</button>
                        </div>
                    </div>
                </div> -->
                <div class="mb-3 col-7 col-md-10">
                    <input formControlName="closed_out" class="form-check-input" type="checkbox" value id="flexCheckDefault">
                    <label for="address3" class="form-label">
                        &nbsp; Closed out
                    </label>
                </div>
            </div>
        </div>
        <button type="submit" class="save-btn" (click)='onClickBtn()'>
            NEXT
        </button>
    </form>
</div>