import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import * as AWS from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from './auth.service';
// var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
// var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  signInForm: any;
  isLoading: boolean = false;
  isLoginFailed: boolean = false;
  // title = 'amplify-angular-auth';
  // user: CognitoUserInterface | undefined;
  // authState: AuthState;
  data = undefined;
  constructor(private ref: ChangeDetectorRef, private router: Router, private authService: AuthService) {
    // this.authState! = any[];
  }

  ngOnInit(): void {
    let currentuser = this.authService.currentUserValue;
    if(currentuser && currentuser.signInUserSession){
      this.router.navigate(['']);
    }
    this.signInForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  // ngOnInit(): void {
  // //   const PC = {
  // //     UserPoolId: 'Your user pool id',
  // //     ClientId: 'Your Client ID'
  // // };
  // // const userPool = new CognitoUserPool(PC);
  // // var email = "guruvema1@gmail.com"
  // var email = "suresh.padam5@gmail.com"
  // var mobile = "+918919118673"
  // var password = "11111111"
  // this.signIn(email, password);


  // // onAuthUIStateChange((authState, authData) => {
  // //   this.authState = authState;
  // //   this.user = authData as CognitoUserInterface;
  // //   this.ref.detectChanges();
  // // })


  // // var authenticationData = {
  // //   Username: email,
  // //   Password: password,
  // // };

  // // var authenticationDetails = new AWS.AuthenticationDetails(
  // //   authenticationData
  // // );
  // // var poolData = {
  // //   UserPoolId: 'us-west-2_ayIHmieKL', // Your user pool id here
  // //   ClientId: '6aatr86t366uuto3a9o2si4ull', // Your client id here
  // // };
  // // var userPool = new AWS.CognitoUserPool(poolData);
  // // var userData = {
  // //   Username: mobile,
  // //   Pool: userPool,
  // // };
  // // var cognitoUser = new AWS.CognitoUser(userData);
  // // cognitoUser.authenticateUser(authenticationDetails, {
  // //   onSuccess: function(result) {
  // //     //console.log("authenticateUser()", result)
  // //   },
  // //   onFailure: function(err) {
  // //     //console.log("authenticateUser()", err)
  // //     alert(err.message || JSON.stringify(err));
  // //   },
  // // });

  // // cognitoUser.getUserAttributes(function(err, result) {
  // //   if (err) {
  // //     // alert(err.message || JSON.stringify(err));
  // //     //console.log(err)
  // //     // return;
  // //   }
  // //   // for (let i = 0; i < result.length; i++) {
  // //     // //console.log(
  // //     //   'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
  // //     // );
  // //   // }
  // //   //console.log(err)
  // //   //console.log(result)
  // // });
  //       // onSuccess: function(result) {
  //   //   //console.log("authenticateUser()", result);
  //   //   var accessToken = result.getAccessToken().getJwtToken();

  //     //POTENTIAL: Region needs to be set if not already set previously elsewhere.
  //     // AWS.config.region = '<region>';

  //     // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //     //   IdentityPoolId: '...', // your identity pool id here
  //     //   Logins: {
  //     //     // Change the key below according to the specific region your user pool is in.
  //     //     'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>': result
  //     //       .getIdToken()
  //     //       .getJwtToken(),
  //     //   },
  //     // });

  //     //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
  //     // AWS.config.credentials.refresh(error => {
  //     //   if (error) {
  //     //     console.error(error);
  //     //   } else {
  //     //     // Instantiate aws sdk service objects now that the credentials have been updated.
  //     //     // example: var s3 = new AWS.S3();
  //     //     //console.log('Successfully logged!');
  //     //   }
  //     // });
  // //   },

  // //   onFailure: function(err) {
  // //     alert(err.message || JSON.stringify(err));
  // //   },
  // //  });

  // }
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    this.isLoading = true;
    const {username, password } = this.signInForm.value;
    let response = await this.authService.login(username, password);
    this.isLoading = false;
    //console.log("response", response)
    // this.signIn(username, password);
    //console.log(response.success);
    if (response.success){
      this.router.navigate(['']);
    }else{
      this.isLoginFailed = true;
    }
    // this.router.navigate(['/auth']);

  }

  // public async signIn(username, password) {
  //   try {
  //     const user = await Auth.signIn(username, password);
  //     // let a = await user.getSignInUserSession();
  //     // //console.log('User', user.getSignInUserSession());
  //     //console.log('User', user);
  //     //console.log('User', user['atttributes']);
  //     // //console.log('typeof(this.a)', typeof(user));
  //     const users = await Auth.currentAuthenticatedUser();
  //     //console.log('users:', users);
  //     // //console.log('attributes:', users['attributes']);
  //     // //console.log('User', users['attributes'])
  //     // const attributes = users.getUserAttributes()
  //     //console.log('attributes', users.attributes)
  //     this.router.navigate(['/']);
  //   } catch (error) {
  //     this.router.navigate(['/foodproviderselect']);
  //     //console.log('error signing in', error);
  //   }
  // }


  get username() { return this.signInForm.get('username'); }
  get password() { return this.signInForm.get('password'); }

  // ngOnDestroy() {
  //   return onAuthUIStateChange;
  // }

  // public  handleSignUp(formData) {
  //   //console.log("formData", formData)
  //   const param = {
  //     ...formData,
  //     attributes: {
  //       ...formData.attributes,
  //       // 'custom:favorite_flavor': 'Cookie Dough'
  //     }
  //   }
  //   var data =  Auth.signUp(param);
  //   alert(data)
  //   //console.log("data", data)
  //   // return data;
  // }
}
