import { Component, OnInit } from '@angular/core';
import { FoodProviderService } from '../../services/food-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-review-tag',
  templateUrl: './review-tag.component.html',
  styleUrls: ['./review-tag.component.css'],
})
export class ReviewTagComponent implements OnInit {
  newlyAddedTags: any = [];
  tagsToBeDeleted: any = [];
  tagsConfigData: any = [];
  isLoading: boolean = true;
  isApiCallDone: boolean = false;
  currentMenu: string = 'Pending';
  newTagsForm!: FormGroup;

  constructor(private foodProviderService: FoodProviderService) {
    this.newTagsForm = new FormGroup({
      tagName: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.getTagsFromConfig();
  }

  getTagsFromConfig(): void {
    this.foodProviderService.getMultipleConfig(['Tag']).subscribe((response: any) => {
      if (response && response.Tag) {
        this.tagsConfigData = response.Tag.sort((a: { ConfigValue: string }, b: { ConfigValue: string }) => a.ConfigValue.localeCompare(b.ConfigValue));
        this.isLoading = false;
        this.isApiCallDone = false;
      }
    });
  }

  addNewTags(): void {
    const newTagValue = this.newTagsForm?.value?.tagName;
    if (newTagValue && !this.newlyAddedTags.includes(newTagValue)) {
      this.newlyAddedTags.push(newTagValue);
      this.newTagsForm.patchValue({ tagName: '' });
    }
  }

  removeTag(tagName: string, action: string): void {
    if (action === 'add') {
      const indexToRemove = this.newlyAddedTags.findIndex((ref: string) => ref === tagName);
      if (indexToRemove !== -1) {
        this.newlyAddedTags.splice(indexToRemove, 1);
      }
    } else if (action === 'delete') {
      this.tagsConfigData.push({ ConfigName: 'Tag', ConfigValue: tagName });
      this.tagsConfigData.sort((a: { ConfigValue: string }, b: { ConfigValue: string }) => a.ConfigValue.localeCompare(b.ConfigValue));
      const indexToRemove = this.tagsToBeDeleted.indexOf(tagName);
      if (indexToRemove !== -1) {
        this.tagsToBeDeleted.splice(indexToRemove, 1);
      }
    }
  }

  deleteTags(item: any): void {
    if (this.newlyAddedTags.length === 0) {
      if (item?.ConfigValue && !this.tagsToBeDeleted.includes(item?.ConfigValue)) {
        this.tagsToBeDeleted.push(item?.ConfigValue);
        const indexToRemove = this.tagsConfigData.findIndex((ref: { ConfigValue: string }) => ref.ConfigValue === item?.ConfigValue);
        if (indexToRemove !== -1) {
          this.tagsConfigData.splice(indexToRemove, 1);
        }
      }
    } else {
      alert('Tags cannot be deleted when you have new tags to add');
    }
  }

  saveTags(): void {
    this.isApiCallDone = true;
    this.foodProviderService.addTagsInConfig({ tags: this.newlyAddedTags }).subscribe((tagResp: any) => {
      if (tagResp === 'success') {
        this.newlyAddedTags = [];
        this.getTagsFromConfig();
      }
    });
  }

  deleteTagsApi(): void {
    this.isApiCallDone = true;
    this.foodProviderService.deleteTagsInConfig({ Tags: this.tagsToBeDeleted }).subscribe((tagResp: any) => {
      if (tagResp === 'success') {
        this.newlyAddedTags = [];
        this.tagsToBeDeleted = [];
        this.isApiCallDone = false;
      }
    });
  }

  handleAction(): void {
    if (this.newlyAddedTags.length > 0) {
      this.saveTags();
    } else if (this.tagsToBeDeleted.length > 0) {
      this.deleteTagsApi();
    }
  }

  getButtonLabel(): string {
    if (this.newlyAddedTags.length > 0) {
      return 'SAVE';
    } else if (this.tagsToBeDeleted.length > 0) {
      return 'DELETE';
    }
    return '';
  }
}  