import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FoodProviderService } from '../../services/food-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Storage } from 'aws-amplify';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const JSON5 = require('json5');

@Component({
  selector: 'app-dish-entry',
  templateUrl: './dish-entry.component.html',
  styleUrls: ['./dish-entry.component.css']
})
export class DishEntryComponent implements OnInit {
  recommended_bev_pairing = new FormControl('');
  recommended_side_and_dish_pairing = new FormControl('');
  add_ons = new FormControl('');
  eatingStyleList: Array<any> = [];
  dishClassificationList: Array<any> = [];
  tagSuggestionsList: Array<any> = [];
  tagSuggestionsOnChange: any = [];
  dishInfoForm: any;
  mediaArr: any = [];
  selectedFiles: any[] = [];
  previews: string[] = [];
  Dishes: any = [];
  AWSCSDishes: any = [];
  recommendedDishes: any[] = [];
  recommendedAddons: any[] = [];
  currentRestaurant: any;
  currentDish: any;
  nutrition: any = {};
  disableMode: boolean = false;
  isLoading: boolean = false;
  imagesUploading: boolean = false;
  fetchImages: boolean = false;
  progressFlowType: any = '';
  contentList: Array<string> = ['Image', 'Video'];
  dishTypeList: Array<string> = ['Dish', 'Add on'];
  tags: any = [];
  tagsObj: any = {};
  constructor(private _Activatedroute: ActivatedRoute, private formBuilder: FormBuilder, private foodProviderService: FoodProviderService, private router: Router) {
    let configData = this.foodProviderService.getConfigData();
    this.eatingStyleList = JSON.parse(configData).EatingStyle;
    this.dishClassificationList = JSON.parse(configData).DishClassification;
    this.tagSuggestionsList = JSON.parse(configData).Tag;
    this.dishInfoForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      price: new FormControl('', [Validators.pattern('[0-9.]*')]),
      dish_classification: this.formBuilder.array(this.dishClassificationList.map(x => !1)),
      ingredients: new FormControl(''),
      recommended_bev_pairing: this.recommended_bev_pairing,
      recommended_side_and_dish_pairing: this.recommended_side_and_dish_pairing,
      serving_size: new FormControl('', [Validators.pattern('[0-9.]*')]),
      calories: new FormControl('', [Validators.pattern('[0-9]*')]),
      fat: new FormControl('', [Validators.pattern('[0-9.]*')]),
      cholesterol: new FormControl('', [Validators.pattern('[0-9.]*')]),
      carbohydrates: new FormControl('', [Validators.pattern('[0-9.]*')]),
      fiber: new FormControl('', [Validators.pattern('[0-9.]*')]),
      sugar: new FormControl('', [Validators.pattern('[0-9.]*')]),
      protein: new FormControl('', [Validators.pattern('[0-9.]*')]),
      sodium: new FormControl('', [Validators.pattern('[0-9.]*')]),
      iron: new FormControl('', [Validators.pattern('[0-9.]*')]),
      mediaInput: new FormControl('', [Validators.maxLength(1000)]),
      eating_styles: this.formBuilder.array(this.eatingStyleList.map(x => !1)),
      nutritionName: new FormControl(''),
      nutritionValue: new FormControl('', [Validators.pattern('[0-9.]*')]),
      popular_item: new FormControl(''),
      contentType: new FormControl('Image'),
      dishType: new FormControl('Dish'),
      vitamin_a: new FormControl('', [Validators.pattern('[0-9.]*')]),
      vitamin_c: new FormControl('', [Validators.pattern('[0-9.]*')]),
      tagName: new FormControl(''),
      add_ons: this.add_ons,
    });
  }

  ngOnInit(): void {
    this.currentRestaurant = this.foodProviderService.getCurrentRestaurant();
    this.progressFlowType = localStorage.getItem('flowType');
    this.disableMode = this._Activatedroute.snapshot.params.flowType === 'edit';
    if (this.currentRestaurant === undefined) {
      this.router.navigate(['/'])
    }
    this.currentDish = this.foodProviderService.getCurrentDish();
    let data = this.currentDish;
    if (data.fields !== undefined) {
      if (data.fields.ingredients) {
        data.fields['ingredients'] = JSON.parse(data.fields.ingredients)
      }
      if (data.fields.media) {
        this.mediaArr = JSON5.parse(data.fields.media)
      }
      if (data.fields.popular_item) {
        data.fields['popular_item'] = data.fields.popular_item !== '0';
      }
      if (data.fields.price) {
        data.fields['price'] = parseFloat(data.fields.price).toFixed(2)
      }
      if (data.fields.nutrition) {
        this.nutrition = JSON.parse(data.fields.nutrition)
      }
      if (data.fields.tags) {
        this.tags = JSON.parse(data.fields.tags)
      }
      if (data.fields.dish_classification) {
        let dishClassification = data.fields.dish_classification
        let newDishClassification: any = []
        this.dishClassificationList.forEach((element) => {
          newDishClassification.push(dishClassification.includes(element.ConfigValue))
        })
        data.fields['dish_classification'] = newDishClassification
      }
      if (data.fields.eating_styles) {
        let eatingStyles = data.fields.eating_styles
        let newEatingStyles: any = []
        this.eatingStyleList.forEach((element) => {
          newEatingStyles.push(eatingStyles.includes(element.ConfigValue))
        })
        data.fields['eating_styles'] = newEatingStyles
      }
      if (data.fields.record_type) {
        if (data.fields.record_type == 'Dish') {
          this.dishInfoForm.patchValue({ dishType: 'Dish'});
        } else if (data.fields.record_type == 'Addon') {
          this.dishInfoForm.patchValue({ dishType: 'Add on'});
        }
      }
    }
    this.foodProviderService.getDishorAddons('Dish').subscribe((response) => {
      this.recommendedDishes = response.hits.hit;
      if (data.fields !== undefined && data.fields.recommended_bev_pairing) {
        let recommendedbevpairing = JSON.parse(data.fields.recommended_bev_pairing)
        let recommended_bev_pairing_obj: any = []
        recommendedbevpairing.forEach((item: any) => {
          this.recommendedDishes.forEach((item_1) => {
            if (item === item_1.id) {
              recommended_bev_pairing_obj.push(item_1)
            }
          })
        })
        data.fields['recommended_bev_pairing'] = recommended_bev_pairing_obj;
      }
      if (data.fields !== undefined && data.fields.recommended_side_and_dish_pairing) {
        let recommendedside = JSON.parse(data.fields.recommended_side_and_dish_pairing)
        let recommended_side_obj: any = []
        recommendedside.forEach((item: any) => {
          this.recommendedDishes.forEach((item_1) => {
            if (item === item_1.id) {
              recommended_side_obj.push(item_1)
            }
          })
        })
        data.fields['recommended_side_and_dish_pairing'] = recommended_side_obj;
      }
    })
    this.foodProviderService.getDishorAddons('Addon').subscribe((response) => {
      this.recommendedAddons = response.hits.hit;
      if (data.fields !== undefined && data.fields.add_ons) {
        let addon = JSON.parse(data.fields.add_ons)
        let addonObj: any = []
        addon.forEach((item: any) => {
          this.recommendedAddons.forEach((item_1) => {
            if (item === item_1.id) {
              addonObj.push(item_1)
            }
          })
        })
        data.fields['add_ons'] = addonObj;
      }
      this.dishInfoForm.patchValue(data.fields);
    })

  }
  tagSuggestions(tagname: any) {
    let sampleArray: any = [];
    if (tagname != undefined && tagname !== '' && this.tagSuggestionsList.length != 0) {
      this.tagSuggestionsList.forEach(item => {
        if (item.ConfigValue) {
          if (item.ConfigValue.toLowerCase().startsWith(tagname.toLowerCase())) {
            sampleArray.push(item.ConfigValue);
          }
        }
        this.tagSuggestionsOnChange = [...new Set(sampleArray)]
      });
    }
  }
  fetchGoogleImages() {
    if (this.dishInfoForm.value.name !== '') {
      this.fetchImages = true;
      this.foodProviderService.getGoogleImagesFromLambda(this.dishInfoForm.value.name).subscribe((imageResponse) => {
        if (imageResponse) {
          this.modifyImageData(imageResponse.image_results.sort(() => Math.random() - 0.5).slice(0, 6));
        }
      })
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.mediaArr, event.previousIndex, event.currentIndex);
  }
  modifyImageData(imageData: any) {
    for (let i = 0; i < imageData.length; i++) {
      this.mediaArr.push({ type: 'Image', url: imageData[i].sourceUrl, source: imageData[i].source });
    }
    this.fetchImages = false;
  }
  onSubmit() {
    this.isLoading = true;
    let restaurant = this.currentRestaurant;
    let dish = this.currentDish;
    const { name, description, price, dish_classification, recommended_bev_pairing, add_ons, recommended_side_and_dish_pairing, serving_size,
      calories, fat, vitamin_a, vitamin_c, cholesterol, popular_item, carbohydrates, fiber, sugar, protein, sodium, iron, ingredients, eating_styles, dishType } = this.dishInfoForm.value;
    let restId = restaurant.fields.name + '_' + restaurant.fields.location_address_1;
    let dishClassification: Array<string> = [];
    dish_classification.forEach((element: boolean, index: number) => {
      if (element) {
        dishClassification.push(this.dishClassificationList[index].ConfigValue);
      }
    });
    let dishFinalClassification = dishClassification;
    let eatingStyles: Array<string> = [];
    eating_styles.forEach((element: boolean, index: number) => {
      if (element) {
        eatingStyles.push(this.eatingStyleList[index].ConfigValue);
      }
    });
    let dishFinalEatingStyles = eatingStyles;
    let formattedAddons: any = [];
    if (add_ons !== '' && add_ons.length != 0) {
      add_ons.forEach((item: any) => {
        formattedAddons.push(item.id)
      })
    } else { formattedAddons = [] }
    let formettedrecommendedbevpairing:any= [];
    if (recommended_bev_pairing !== '' && add_ons.length != 0) {
      recommended_bev_pairing.forEach((item: any) => {
        formettedrecommendedbevpairing.push(item.id)
      })
    } else { formettedrecommendedbevpairing = [] }
    let formettedrecommendedside:any= [];
    if (recommended_side_and_dish_pairing !== '' && recommended_side_and_dish_pairing.length != 0) {
      recommended_side_and_dish_pairing.forEach((item: any) => {
        formettedrecommendedside.push(item.id)
      })
    } else { formettedrecommendedside = [] }
    let dishObject = {
        'recommended_side_and_dish_pairing':JSON.stringify(formettedrecommendedside),
        'record_type': dishType === 'Add on' ? 'Addon' : 'Dish',
        'eating_styles': dishFinalEatingStyles,
        'ingredients': ingredients !== '' && typeof ingredients !== 'object' ? JSON.stringify(ingredients.split(',')) : ingredients !== '' && typeof ingredients === 'object' ? JSON.stringify(ingredients) : "[]",
        'protein': protein !== '' ? protein : undefined,
        'category': restaurant.fields.category !== undefined ? restaurant.fields.category : "[]",
        'payment_options': restaurant.fields.payment_options !== undefined ? restaurant.fields.payment_options : "[]",
        'location_country': restaurant.fields.location_country !== undefined ? restaurant.fields.location_country : '',
        'closed_out': restaurant.fields.closed_out !== undefined ? restaurant.fields.closed_out ? '1' : '0' : '',
        'media': JSON.stringify(this.mediaArr),
        'carbohydrates': carbohydrates !== '' ? carbohydrates : undefined,
        'coordinates': restaurant.fields.coordinates !== undefined ? restaurant.fields.coordinates : '',
        'sugar': sugar !== '' ? sugar : undefined,
        'source': 'Trofimo',
        'amenities': restaurant.fields.amenities !== undefined ? restaurant.fields.amenities : "[]",
        'serving_size': serving_size,
        'food_provider_and_location': restaurant.fields.name + '_' + restaurant.fields.location_address_1,
        'vitamin_a': vitamin_a !== '' ? vitamin_a : undefined,
        'vitamin_c': vitamin_c !== '' ? vitamin_c : undefined,
        'fiber': fiber !== '' ? fiber : undefined,
        'description': description,
        'nutrition': JSON.stringify(this.nutrition),
        'open_hours': restaurant.fields.open_hours !== undefined ? restaurant.fields.open_hours : "[]",
        'price': price !== '' ? price : undefined,
        'fat': fat !== '' ? fat : undefined,
        'food_provider_id': restId.replace(/[^a-zA-Z0-9]/g, '_'),
        'location_city': restaurant.fields.location_city !== undefined ? restaurant.fields.location_city : '',
        'recommended_bev_pairing': JSON.stringify(formettedrecommendedbevpairing),
        'add_ons': JSON.stringify(formattedAddons),
        'location_state': restaurant.fields.location_state !== undefined ? restaurant.fields.location_state : '',
        'name': name,
        'oldname': this.disableMode && name !== dish.fields.name ? dish.fields.name : undefined,
        'sodium': sodium !== '' ? sodium : undefined,
        'calories': calories !== '' ? calories : undefined,
        'budget': restaurant.fields.budget !== undefined ? restaurant.fields.budget : '',
        'dish_classification': dishFinalClassification,
        'iron': iron !== '' ? iron : undefined,
        'cholesterol': cholesterol !== '' ? cholesterol : undefined,
        'popular_item': popular_item ? '1' : '0',
        'location_display_address': restaurant.fields.location_display_address !== undefined ? restaurant.fields.location_display_address : '',
        'dining_options': restaurant.fields.dining_options !== undefined ? restaurant.fields.dining_options : "[]",
        'tags': JSON.stringify(this.tags)
    }
    if (this.tags.length != 0) {
      const arrayOfStrings = this.tags.map((item: { tagname: string; }) => item.tagname);
      this.foodProviderService.addTagsInConfig({tags: arrayOfStrings}).subscribe();
    }
    this.foodProviderService.addDish(dishObject).subscribe((response: string) => {
      if (response === 'success') {
        this.dishInfoForm.reset();
        this.isLoading = false;
        Swal.fire({
          text: 'Your changes will be visible in the mobile app within 5 minutes.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/dishlist']);
            this.foodProviderService.setCurrentDish({})
          }
        })
      } else {
        this.isLoading = false;
        Swal.fire({
          text: 'Something went wrong, please try again',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        })
      }
    })
  }
  addMedia() {
    if (this.dishInfoForm.value.mediaInput !== '') {
      if (this.mediaArr.filter((item: any) => item.url == this.dishInfoForm.value.mediaInput).length == 0) {
        this.mediaArr.push({ type: this.dishInfoForm.value.contentType, url: this.dishInfoForm.value.mediaInput, source: 'Trofimo' });
        this.dishInfoForm.patchValue({ mediaInput: '' });
        //console.log('this.mediaArr', this.mediaArr);
      } else {
        alert('Duplicate file')
      }
    }
  }
  removeImage(media: any) {
    this.mediaArr.splice(this.mediaArr.findIndex(item => item.url === media.url), 1);
  }
  removeLocalImage(ref: any) {
    this.previews.splice(this.previews.findIndex(item => item === ref), 1);
  }
  uploadImages = async () => {
    this.imagesUploading = true;
    let localFiles = this.previews;
    let imageNames = this.selectedFiles;
    for (let i = 0; i < localFiles.length; i++) {
      const imageUploading = await this.s3Upload(localFiles[i], imageNames[i].name);
    }
  }
  async s3Upload(image: any, filename: any) {
    let imageName = filename;
    let dishName = this.dishInfoForm.value.name.replace(/[^a-zA-Z0-9]/g, '_');
    let id = this.currentRestaurant.fields.name + '_' + this.currentRestaurant.fields.location_address_1;
    let restaurantId = id.replace(/[^a-zA-Z0-9]/g, '_');
    let pathForStoring = 'webupload/foodproviders/' + restaurantId + '/dishes/' + dishName + '/media/images/';
    let imageUrl = environment.s3path + 'foodproviders/' + restaurantId + '/dishes/' + dishName + '/media/images/' + imageName;
    const response = await fetch(image);
    const blob = await response.blob();
    Storage.put(pathForStoring + imageName, blob, {
      bucket: environment.bucketName,
      level: 'public',
      acl: 'public-read',
    }).then(() => {
      // console.log('uploaded successfully');
      this.selectedFiles = [];
      this.previews = [];
      this.mediaArr.push({ type: this.dishInfoForm.value.contentType, url: imageUrl, source: 'Trofimo' });
      this.imagesUploading = false;
      // console.log('final output of mediaArr', this.mediaArr);
    }).catch((error) => {
      console.log('error while uploading' + error);
    });
  }
  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    // console.log('image metadata', event.target.files);
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // console.log('complete event', e);
          // console.log('blob file', e.target.result);
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
  addNutrition() {
    if (this.dishInfoForm.value.nutritionName && this.dishInfoForm.value.nutritionValue !== '') {
      this.nutrition[this.dishInfoForm.value.nutritionName] = this.dishInfoForm.value.nutritionValue
    }
    this.dishInfoForm.patchValue({ nutritionName: '', nutritionValue: '' });
    //console.log('addNutrition', this.nutrition);
  }
  removeNutrition(key: any) {
    delete this.nutrition[key];
  }
  get price() {
    return this.dishInfoForm.get('price');
  }
  get name() {
    return this.dishInfoForm.get('name');
  }
  get servingSize() {
    return this.dishInfoForm.get('serving_size');
  }
  get calories() {
    return this.dishInfoForm.get('calories');
  }
  get fat() {
    return this.dishInfoForm.get('fat');
  }
  get cholesterol() {
    return this.dishInfoForm.get('cholesterol');
  }
  get carbohydrates() {
    return this.dishInfoForm.get('carbohydrates');
  }
  get vitamin_a() {
    return this.dishInfoForm.get('vitamin_a');
  }
  get vitamin_c() {
    return this.dishInfoForm.get('vitamin_c');
  }
  get mediaInput() {
    return this.dishInfoForm.get('mediaInput');
  }
  get ratings() {
    return this.dishInfoForm.get('ratings');
  }
  get nutritionValue() {
    return this.dishInfoForm.get('nutritionValue');
  }
  get fiber() {
    return this.dishInfoForm.get('fiber');
  }
  get sugar() {
    return this.dishInfoForm.get('sugar');
  }
  get protein() {
    return this.dishInfoForm.get('protein');
  }
  get sodium() {
    return this.dishInfoForm.get('sodium');
  }
  get iron() {
    return this.dishInfoForm.get('iron');
  }
  get tagName() { return this.dishInfoForm.get('tagName'); }

  removeTag(key: any) {
    this.tags.splice(key, 1);
  }
  addTag(tagName: any) {
    if (tagName !== '') {
      if (this.tags.filter((item: any) => item.tagname == tagName).length == 0) {
        this.tagsObj = {}
        this.tagsObj['tagname'] = tagName
        this.tagsObj['count'] = 1
        this.tags.push(this.tagsObj)
      } else {
        alert('Tag already added.')
      }
    }
    this.dishInfoForm.patchValue({ tagName: '' });
    this.tagSuggestionsOnChange = [];
  }
}
