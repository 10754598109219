<div class="container mt-5">
    <form [formGroup]="listSearchForm" #formDir="ngForm">
        <div class="col-12 col-md-6 mb-5">
            <div class="mb-3 col-7 col-md-8">
                <label for="listName" class="form-label">
                    List Name
                </label>
                <input type="text" class="form-control" formControlName="listName" id="listName" placeholder="Enter list name" required>
                <div *ngIf="listName.invalid && (listName.dirty || listName.touched)" class="alert alert-danger mt-2">
                    <div *ngIf="listName.errors?.required">
                        List name is required.
                    </div>
                </div>
            </div>
            <div class="mb-3 col-7 col-md-8">
                <label for="searchScope" class="form-label">
                    Scope (Optional)
                </label>
                <input type="text" class="form-control" formControlName="searchScope" id="searchScope" placeholder="Enter scope">
            </div>
            <div style="width: 220px;" *ngIf="noResultsFound" class="alert alert-danger mt-2">
                No results found.
            </div>
            <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
            <ng-template #thenBlockBtn>
                <button class="save-btn-disabled mb-5" type="button" disabled>
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Searching...
                </button>
            </ng-template>
            <ng-template #elseBlockBtn>
                <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !(listSearchForm.valid)}" [disabled]="!(listSearchForm.valid)" (click)="onSearch()">
                    SEARCH
                </button>
            </ng-template>
        </div>
        <div class="col-12 col-md-4 mb-5">
            <ul class="list-group">
                <li *ngFor="let list of curatedList" [ngClass]="{'list-group-item': true, 'list-group-item-action': true}">
                    <div class="d-flex flex-row">
                        <div class="col-md-8">
                            {{list.Name}} from {{list.CuratedListFor}}
                        </div>
                        <button type="button" class="col-md-2" (click)="navigateToList(list)" class="edit-btn">
                            EDIT
                        </button>
                        <button type="button" class="col-md-2" (click)='setCurrentList(list)' data-bs-toggle="modal" data-bs-target="#deleteModal" class="delete-btn">
                            <img
                                alt="Image"
                                style="width: 14px; height: 14px;"
                                src="assets/images/delete_white 3.png"
                            >
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </form>
</div>
<!-- Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                    Are you sure want to delete
                    <br>
                    <strong>{{getListName()}}</strong> ?
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-footer">
                <button type="button" class="edit-btn" style="width: 90px;" data-bs-dismiss="modal">
                    CANCEL
                </button>
                <button (click)='removeItem()' type="button" class="edit-btn" style="width: 90px; color: #FFFFFF; background-color: #FF8A3B;" data-bs-dismiss="modal">
                    DELETE
                </button>
            </div>
        </div>
    </div>
</div>