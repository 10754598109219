<div class="container mt-5">
    <form [formGroup]="tagEntryForm" #formDir="ngForm">
        <div class="mb-3 col-3">
            <label for="name" class="form-label">Tag</label>
            <input required type="text" class="form-control" formControlName="tagName" id="tagName"
                placeholder="Specify a Tag">
        </div>
        <div class="mb-3 col-3">
            <label for="name" class="form-label">Search Input</label>
            <input type="text" class="form-control" formControlName="searchInput"
            id="entryInput" placeholder="Input item name" (ngModelChange)="filterRecords($event)">
        </div> 
        <h4>
            Apply to...
        </h4>
        <div class="mb-3 col-7 col-md-10">
            <h5>
                Restaurants
            </h5>
            <div class="col-md-5">
                <ng-multiselect-dropdown [placeholder]="'Select Items'" [data]="restRecords"
                    (onSelect)="onItemSelect($event, 'Restaurant')" (onDeSelect)="onItemDeSelect($event, 'Restaurant')"
                    [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="mb-3 col-7 col-md-10">
            <h5>
                Dishes
            </h5>
            <div class="col-md-5">
                <ng-multiselect-dropdown [placeholder]="'Select Items'" [data]="dishRecords"
                    (onSelect)="onItemSelect($event, 'Dish')" (onDeSelect)="onItemDeSelect($event, 'Dish')"
                    [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div>
            <div *ngIf="isLoading; then thenBlockBtn else elseBlockBtn"></div>
            <ng-template #thenBlockBtn>
                <button class="save-btn-disabled mb-5" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </ng-template>
            <ng-template #elseBlockBtn>
                <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': false}" (click)="onSubmit()">
                    SAVE
                </button>
            </ng-template>
        </div>
    </form>
</div>