<div class="container mt-5">
    <!-- <amplify-authenticator>
        <amplify-sign-in
          header-text="My Custom Sign In Text"
          slot="sign-in"
        ></amplify-sign-in>
      </amplify-authenticator> -->
    <!-- <div *ngIf="a === 'jbkjbkj'" class="App">

        <p>{{a}}</p>
    </div> -->

    <form [formGroup]="signInForm">
        <div class="mb-3 col-3">
            <label for="username" class="form-label">Email or Mobile</label>
            <input type="text" class="form-control" formControlName="username" id="username" placeholder="Enter your email/mobile" required>
            <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger mt-2">
                <div *ngIf="username.errors?.required">
                    Username is required.
                </div>
            </div>
        </div>

        <div class="mb-3 col-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" formControlName="password" id="userPassword" placeholder="Enter your password" required>
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger mt-2">
                <div *ngIf="password.errors?.required">
                    Password is required.
                </div>
            </div>
        </div>
        <div *ngIf="isLoading; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
            <button class="save-btn-disabled mb-5" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
            </button>
        </ng-template>
        <ng-template #elseBlock>
            <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !signInForm.valid}" [disabled]="!signInForm.valid" (click)="onSubmit()">LOGIN</button>
        </ng-template>
        <div *ngIf="isLoginFailed && !isLoading" class="alert alert-danger mt-4 mb-3 col-3" role="alert">
            Username or Password is incorrect.
          </div>
    </form>
</div>