<div class="container mt-5">
  <div style="display: flex; align-items: center;">
    <div class="circle"></div>
    <span class="fs-5">&nbsp; Start</span>
    <span class="fs-5">-----</span>
    <div class="circle"></div>
    <span class="fs-5">&nbsp; Select</span>
  </div>
  <hr class="line" style="left: 0; right: 0">
  <form [formGroup]="foodProviderQueryForm">
    <div class="mb-3 col-3">
      <label for="foodProviderName" class="form-label">Food provider name</label>
      <input type="text" class="form-control" formControlName="name" id="foodProviderName"
        placeholder="Input food provider name" required>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger mt-2">
          <div *ngIf="name.errors?.required">
            Food provider name is required.
          </div>
        </div>
    </div>

    <div class="mb-3 col-3">
      <label for="foodProviderCity" class="form-label">Food provider city</label>
      <input type="text" class="form-control" formControlName="city" id="foodProviderCity"
        placeholder="Input food provider city" required>
        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert alert-danger mt-2">
          <div *ngIf="city.errors?.required">
            City is required.
          </div>
        </div>
    </div>
    <button type="button" [ngClass]="{'save-btn': true, 'disabled-btn': !foodProviderQueryForm.valid}" [disabled]="!foodProviderQueryForm.valid" (click)="onSubmit()">SUBMIT</button>
  </form>
</div>