import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FoodProviderService } from '../../services/food-provider.service';

@Component({
  selector: 'app-admin-approval',
  templateUrl: './admin-approval.component.html',
  styleUrls: ['./admin-approval.component.css'],
})
export class AdminApprovalComponent implements OnInit {
  tagDetails: any;
  restaurantDetails: any;
  configData: any;
  isLoadingApprove: boolean = false;
  isLoadingReject: boolean = false;
  isLoading: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private foodProviderService: FoodProviderService, private route: Router) {
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.foodProviderService.getTag(
      this.activatedRoute.snapshot.paramMap.get('username'),
      this.activatedRoute.snapshot.paramMap.get('createddate'))
      .subscribe((response: any) => {
        this.tagDetails = response.Item;
        if (response && response.Item) {
          this.foodProviderService
            .getItemById(response.Item.ItemId)
            .subscribe((restResponse: any) => {
              this.isLoading = false;
              if (restResponse) {
                this.restaurantDetails = restResponse.hits.hit[0].fields;
              }
            });
        }
        this.isLoading = false;
      });
  }
  getExistingTags() {
    if (this.restaurantDetails && this.restaurantDetails.tags && this.restaurantDetails.tags.length !== 0) {
      return this.restaurantDetails.tags.map(x => x.tagname).join(",")
    } else {
      return 'No Tags'
    }
  }
  onSubmit(flag: string) {
    if (flag == 'APPROVE') {
      this.isLoadingApprove = true;
      let reqObj = {
        tag: this.tagDetails.TagName,
        Username: this.tagDetails.Username,
        CreatedDate: this.tagDetails.CreatedDate,
        name: this.restaurantDetails.name,
        food_provider_and_location: this.restaurantDetails.food_provider_and_location,
        location_address_1: this.restaurantDetails.location_address_1,
        RecordType: this.tagDetails.RecordType
      }
      this.foodProviderService.approveTag(reqObj).subscribe((response) => {
        if (response === 'success') {
          this.isLoadingApprove = false;
          Swal.fire({
            text: 'The tag is public now.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.route.navigate(['/reviewtag']);
            }
          })
        } else {
          this.isLoadingApprove = false;
          Swal.fire({
            text: 'Something went wrong, please try again',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          })
        }
      })
    } else {
      this.isLoadingReject = true;
      let reqObj = {
        Username: this.tagDetails.Username,
        CreatedDate: this.tagDetails.CreatedDate,
      }
      this.foodProviderService.deleteTag(reqObj).subscribe((response) => { 
        if (response === 'success') {
          this.isLoadingReject = false;
          Swal.fire({
            text: 'Tag deleted successfully.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.route.navigate(['/reviewtag']);
            }
          })
        } else {
          this.isLoadingReject = false;
          Swal.fire({
            text: 'Something went wrong, please try again',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          })
        }
       })
    }
  }
}
